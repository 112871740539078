import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import api from '../../api/api';
import { useAuth } from '../../context/AuthContext';

const DashboardChart = () => {
  const { user } = useAuth();
  const [state, setState] = useState({
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: [],
      },
      colors: ['#067DD7', '#050A27'],
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            // seriesIndex 0 ise "Fatura Sayısı", 1 ise "Fatura Fiyatı"
            const seriesName = w.config.series[seriesIndex].name;
            
            let unit = '';
            if (seriesName === 'Fatura Sayısı') {
              unit = ' adet';
            } else if (seriesName === 'Fatura Fiyatı') {
              unit = '&#8378;';
            }

            return val + unit;
          },
        },
      },
    },
    
    series: [
      {
        name: 'Fatura Sayısı',
        data: [], // invoiceCount değerleri burada olacak
      },
      {
        name: 'Fatura Fiyatı',
        data: [], // invoicePrice değerleri burada olacak
      },
    ],
  });

  useEffect(() => {
    if  (user && user.country){
    api
      .get(`/api/Report/GetDailySales?customerID=${user.country}&startDate=2024-1-1&endDate=2024-1-31`)
      .then((res) => {
        const categories = res.data.map((item) => new Date(item.invoiceTime).getDate());
        const invoiceCountData = res.data.map((item) => item.invoiceCount);
        const invoicePriceData = res.data.map((item) => item.invoicePrice);

        // State'i güncelle
        setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              categories,
            },
          },
          series: [
            {
              ...prevState.series[0],
              data: invoiceCountData,
            },
            {
              ...prevState.series[1],
              data: invoicePriceData,
            },
          ],
        }));
      })
      .catch((err) => {
        console.log(err.data);
      });
    }
  }, [user]);

  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg ">

  <Chart options={state.options} series={state.series} type='area' height={350} />
    </div>
  
  )
};

export default DashboardChart;