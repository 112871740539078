import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const WorkOrdersGraph = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user && user.country) {
      api
        .get(`/api/Report/ReportWorkOrders?customerID=${user.country}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <Link to={"/workOrders"}>
      <div className="w-full bg-white border border-gray-200 rounded-lg sm:p-10 cursor-pointer">
        <div className="flow-root">
          <ul role="list" className="divide-y divide-gray-200">
            <li className="py-3 sm:py-4">
              <div className="flex items-center">
                <div className="flex-1 min-w-0 ms-4">
                  <p className="text-base font-semibold text-yellow-600 truncate">
                    Toplam İş Emirleri
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-yellow-600">
                  {data?.totalWorkOrders|| 0}
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center">
                <div className="flex-1 min-w-0 ms-4">
                  <p className="text-base font-semibold text-blue-600 truncate">
                    Devam Eden İş Emirleri
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-blue-600">
                  {data?.onGoingWorkOrders|| 0}
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center">
                <div className="flex-1 min-w-0 ms-4">
                  <p className="text-base font-semibold text-red-600 truncate">
                    Geciken İş Emirleri
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-red-600">
                  {data?.delayedWorkOrders ||0}
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center">
                <div className="flex-1 min-w-0 ms-4">
                  <p className="text-base font-semibold text-green-600 truncate">
                    Tamamlanan İş Emirleri
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-green-600">
                  {data?.complatedWorkOrders || 0}
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center">
                <div className="flex-1 min-w-0 ms-4">
                  <p className="text-base font-semibold text-gray-500 truncate">
                    Tamamlanan İş Emirleri Yüzdesi
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-500">
                  %{data?.percantageComplatedWorkOrders||0}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Link>
  );
};

export default WorkOrdersGraph;
