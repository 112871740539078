import React from "react";
import error from "../assets/images/error.gif";

const Error = () => {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-gray-200 text-gray-800 text-center font-sans p-5">
      <img src={error} alt="Hata" className="max-w-[80%] h-auto mb-[20px]" />
      <h1 className="text-2xl my-2">Üzgünüz! Sayfa Bulunamadı</h1>
      <p className="text-xl mb-5">
        Aradığınız sayfa kaldırılmış, adını değiştirmiş veya geçici olarak
        kullanılamıyor olabilir.
      </p>
      <a
        href="/dashboards"
        className="text-white bg-[#050A27] hover:bg-[#050a27c5] px-5 py-2.5 rounded"
      >
        Ana Sayfaya Git
      </a>
    </div>
  );
};

export default Error;
