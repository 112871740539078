import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const WorkOrderClosing = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [token, setToken] = useState(null);
  //FORM
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [description, setDescription] = useState("");

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=27`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/WorkOrder/Get?id=${params.id}&customerID=${user.country}`
            )
            .then((res) => {
              setData(res.data);
              setEndDate(new Date(res.data.endDate));
              setStartDate(new Date(res.data.startDate));
              setDescription(res.data.description);
            })
            .catch((err) => console.log(err));

          api
            .get(
              `/api/WorkOrder/GetWorkOrderItems?customerID=${user.country}&workOrderID=${params.id}`
            )
            .then((res) => {
              const dataItem = res.data;
              const newDataItem = dataItem.map((item) => ({
                workOrderItemID: item.workOrderItemID,
                name: item.name,
                startDate: new Date(item.startDate),
                endDate: new Date(item.endDate),
                plannedProductionQuantity: item.plannedProductionQuantity,
                actualProductionQuantity: item.actualProductionQuantity,
                description: item.description,
                machineName: item?.machineName,
                operationName: item?.operationName,
              }));
              setDataItems(newDataItem);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItems = [...dataItems];
    updatedItems[index][name] = value;

    // Check if the actual production quantity is less than the planned quantity
    if (name === "actualProductionQuantity" && value) {
      updatedItems[index].showOrderCheckbox =
        parseInt(value) < updatedItems[index].plannedProductionQuantity;
    }

    setDataItems(updatedItems);
  };

  const handleStartDateChange = (e, index) => {
    const list = [...dataItems];
    list[index].startDate = e;
    setDataItems(list);
  };

  const handleEndDateChange = (e, index) => {
    const list = [...dataItems];
    list[index].endDate = e;
    setDataItems(list);
  };
  const postData = {
    customerID,
    workOrderID: params.id,
    name: data?.name,
    startDate,
    endDate,
    description,
    workOrderClosingItems: dataItems.map((item) => ({
      workOrderItemID: item.workOrderItemID,
      startDate: item.startDate,
      endDate: item.endDate,
      productionQuantity: item.actualProductionQuantity,
      actualProductionQuantity: item.actualProductionQuantity,
      description: item.description,
    })),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }
      // Axios isteği yapılandırma
      await api.post(
        "/api/WorkOrder/PostWorkOrderClosing/PostWorkOrderClosing",
        postData,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "İş emri kapatıldı !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/workOrders");
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Üretim Planlama", href: "/workOrders" },
                { label: "İş Emri Kapatma", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">
                  İş Emri Kapatma
                </h1>
              </div>
              <form
                className="grid grid-cols-4 gap-7 items-center w-full"
                onSubmit={handleSubmit}
              >
                <div className="col-span-4">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    İş Emri Adı :
                  </label>
                  <input
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    disabled
                    value={data?.name}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Baş. Tarih :
                  </label>
                  <DatePicker
                    id="startDate"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    showTimeSelect
                    locale={tr}
                    dateFormat="dd/MM/yyyy - HH:mm"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Bit. Tarih :
                  </label>
                  <DatePicker
                    id="startDate"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    showTimeSelect
                    locale={tr}
                    dateFormat="dd/MM/yyyy - HH:mm"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>

                <div className="col-span-4">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <textarea
                    id="description"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className="relative overflow-x-auto mb-5 col-span-4 mt-12">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Makine/Operasyon
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Baş.Tarih
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Bit.Tarih
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Planlanan
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Gerçekleşen
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Açıklama
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataItems?.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-6 py-4">
                            <input
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              disabled
                              value={singleService.name}
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              disabled
                              value={
                                singleService?.machineName ||
                                singleService?.operationName
                              }
                            />
                          </td>
                          <td className="px-6 py-4">
                            <DatePicker
                              id="startDate"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              showTimeSelect
                              locale={tr}
                              dateFormat="dd/MM/yyyy - HH:mm"
                              placeholderText="Tarih seçiniz..."
                              selected={singleService.startDate}
                              onChange={(e) => handleStartDateChange(e, index)}
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <DatePicker
                              id="endDate"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              showTimeSelect
                              locale={tr}
                              dateFormat="dd/MM/yyyy - HH:mm"
                              placeholderText="Tarih seçiniz..."
                              selected={singleService.endDate}
                              onChange={(e) => handleEndDateChange(e, index)}
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              value={singleService.plannedProductionQuantity}
                              disabled
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              name="actualProductionQuantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                              value={singleService.actualProductionQuantity}
                              onChange={(e) => handleServiceChange(e, index)}
                            />
                            {singleService.showOrderCheckbox && (
                              <div className="mt-2 flex items-center">
                                <input
                                  type="checkbox"
                                  id={`createOrder-${index}`}
                                  name={`createOrder-${index}`}
                                  className="mr-2"
                                />
                                <label
                                  htmlFor={`createOrder-${index}`}
                                  className="text-sm text-gray-700"
                                >
                                  Eksik adet için iş emri oluştur
                                </label>
                              </div>
                            )}
                          </td>
                          <td className="px-6 py-4">
                            <input
                              name="description"
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              placeholder="Açıklama"
                              value={singleService.description}
                              onChange={(e) => handleServiceChange(e, index)}
                              required
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="col-span-4 flex justify-end gap-[20px]">
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default WorkOrderClosing;
