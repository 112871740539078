import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import { useTranslation } from "react-i18next";

const JobAdd = ({ onCancel = () => {}, onSubmitCancel = () => {} }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [customerID, setCustomerID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedJob, setSelectedJob] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && user.country) {
      setUserID(user.nameidentifier);
      setCustomerID(user.country);

      api
        .get(`/api/User/GetJobs?customerID=${user.country}`)
        .then((res) => {
          const dataJob = res.data;
          const newData = dataJob?.map((item) => ({
            value: item.jobID,
            label: item.name,
          }));
          setData(newData);
        })
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const postData = {
    customerID,
    name,
    parent: selectedJob.value,
    status: 0,
    description,
    leaderUsersID: 0,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Axios isteği yapılandırma
      await api.post("/api/User/PostJob/PostJob", postData);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Başarıyla eklendi !",
        showConfirmButton: false,
        timer: 1500,
      });
      onSubmitCancel();
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey 
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey }) 
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" }); 
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="fixed inset-0 w-screen h-screen bg-black bg-opacity-30 z-50 flex justify-center items-center">
          <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-4 gap-7 items-center"
            >
              <div className="col-span-4">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Birim Adı :
                </label>
                <input
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-span-4">
                <label
                  htmlFor="job"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Bağlı Olduğu Birim :
                </label>
                <Select
                  id="job"
                  options={data}
                  maxMenuHeight={200}
                  value={selectedJob}
                  onChange={(e) => setSelectedJob(e)}
                  placeholder="Seçiniz..."
                  />
              </div>
              <div className="col-span-4">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-span-4 flex justify-center gap-[20px]">
                <button
                  onClick={onCancel}
                  className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
                >
                  Vazgeç
                </button>
                <button
                  type="submit"
                  className="bg-[#0C692F] hover:bg-[#0c6930d3] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
                >
                  Onayla
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default JobAdd;
