import React, { useEffect, useState } from 'react';
import api from "../../api/api"

export default () => {
  const onDragStart = (event, nodeType, nodeId) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('nodeId', nodeId); // Düğümün ID'sini taşı
  };

  const [data,setData] = useState(null)
  useEffect(()=>{
    api.get("/api/Common/GetParameters?parametername=WorkFlowType")
    .then((res)=>setData(res.data))
  },[])
console.log(data);
  return (
    <aside className=''>
      <div className="description">Bu düğümleri solda ki bölmeye sürükleyebilirsiniz.</div>
      {data?.map((item,index)=>(
        <div className={`dndnode  ${item.parameterValue2 === 'Başla' ? 'bg-blue-800 text-white' : (item.parameterValue2 === 'Bitir' ? 'bg-red-800 text-white' : 'bg-slate-800 text-white')}`} onDragStart={(event) => onDragStart(event, `${item.parameterValue2}`, item.parameterID)} draggable>
        {item.parameterValue2}
      </div>
      ))}
     
   
      
    </aside>
  );
};
