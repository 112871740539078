import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../api/api";
import CreatableSelect from "react-select/creatable";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const DepartmentEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  const [departmentData, setDepartmentData] = useState([]);
  const [departmentUser, setDepartmentUser] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [customerID, setCustomerID] = useState(Number());
  const [editUser, setEditUser] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [customerKey, setCustomerKey] = useState(null);
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=16`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => {
              setUsers(res.data);
              const data = res.data;
              const newData = data.map((item) => ({
                value: item.userID,
                label: item.userName,
              }));
              setUserOptions(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Department/Get?id=${params.id}&customerID=${user.country}`
            )
            .then((res) => {
              setDepartmentData(res.data);
              setTitle(res.data.title);
              setName(res.data.name);
              setEditUser(res.data);
              setCustomerID(res.data.customerID);
              setDescription(res.data.description);
              const data = JSON.parse(res.data.keywords);
              const newData = data.map((item) => ({
                value: item,
                label: item,
                __isNew__: true,
              }));
              setKeywords(newData);
            })
            .catch((err) => {
              console.log(err.data);
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  useEffect(() => {
    if (users && departmentData) {
      const matchedUser = users.find(
        (item) => item.userID === departmentData.userID
      );
      setDepartmentUser({
        value: matchedUser?.userID,
        label: matchedUser?.userName,
      });
    }
  }, [users, departmentData]);

  const postData = {
    departmentID: editUser?.departmentID,
    customerID: customerID,
    parentID: editUser?.parentID,
    userID: departmentUser.value,
    name,
    title,
    type: 0,
    status: 0,
    viewIndex: 0,
    description: description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .put("/api/Department/Put/Put", postData)
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Departman başarılı bir şekilde değiştirildi ! ",
          showConfirmButton: false,
          timer: 3000,
        });
        navigate("/departments");
      })
      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey 
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey }) 
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" }); 
        Swal.fire(translatedError);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Departmanlar", href: "/departments" },
                { label: "Departman Güncelle", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">
                  Departman Güncelle
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-2 gap-7 items-center w-full"
              >
                <div className="">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Departman Adı :
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Departman Başlığı :
                  </label>
                  <input
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="keywords"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Anahtar Kelimeler :
                  </label>
                  <CreatableSelect
                    onChange={(e) => setKeywords(e)}
                    value={keywords}
                    options={customerKey}
                    isMulti
                    maxMenuHeight={200}
                    id="keywords"
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                    />
                </div>
                <div className="">
                  <label
                    htmlFor="departmentUser"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kullanıcı Seç :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    id="departmentUser"
                    value={departmentUser}
                    options={userOptions}
                    onChange={(e) => setDepartmentUser(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                    />
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <textarea
                    id="description"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className="col-span-2 flex justify-end gap-[20px]">
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    title="Kaydet"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default DepartmentEdit;
