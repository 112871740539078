import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import { FaRegEye } from "react-icons/fa";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import Breadcrumb from "../../components/bars/Breadcrumb";

const ProjectDetail = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [token, setToken] = useState(null);
  const [projectTask, setProjectTask] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=14`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => {
              const data = res.data;
              const newData = data.map((item) => ({
                value: item.userID,
                label: item.userName,
              }));
              setUsers(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Project/GetProjectTasks?customerID=${user.country}&projectID=${params.id}`
            )
            .then((res) => {
              setProjectTask(res.data);
            })
            .catch((err) => console.log(err));
          api
            .get(`/api/Project/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => setData(res.data))
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    return `${gun}/${ay}/${yil}`;
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Projeler", href: "/projects" },
                { label: data?.title || "Proje Detayı", href: "" },
              ]}
            />
            <div className="w-full p-12 min-h-screen flex flex-col gap-7 items-center my-14">
              <div className="bg-[#050A27] w-full max-w-screen-2xl overflow-hidden">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-3xl font-semibold text-white">
                    {data?.name}
                  </h3>
                  <p className="mt-1 max-w-2xl text-base font-medium text-gray-400">
                    {data?.title}
                  </p>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Atanan Kişi :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data?.user.userName}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Başlangıç Tarihi :
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {formatDate(data?.startDate)}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Bitiş Tarihi :
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {formatDate(data?.endDate)}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Açıklama :
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data?.description}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div className="w-full max-w-screen-2xl">
                <h1 className="text-3xl font-semibold title pl-2">
                  Proje Görevleri
                </h1>
              </div>

              <div className="relative overflow-x-auto w-full max-w-screen-2xl min-h-[400px]">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Görev Adı
                      </th>

                      <th scope="col" className="px-6 py-3">
                        İşlemler
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectTask?.map((item, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b hover:bg-gray-50"
                      >
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {item?.name}
                        </th>
                        <td className="px-6 py-4">
                          <Link
                            to={`../../taskDetailByAssignedUser/${item.taskID}`}
                          >
                            <FaRegEye
                              size={20}
                              className="text-green-600 hover:underline"
                            />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="w-full max-w-screen-2xl flex justify-end items-center gap-3">
                <Link to={`/projectTaskAdd/${params.id}`}>
                  <button
                    type="button"
                    className="bg-[#0C692F] hover:bg-[#0c6930e3] text-white font-bold py-2 px-4 rounded-lg h-12"
                  >
                    Görev Ekle
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </Link>
                <Link to={"/projects"}>
                  <button
                    type="button"
                    className="bg-[#050A27] hover:bg-[#050a27d5] text-white font-bold py-2 px-4 rounded-lg h-12"
                  >
                    <i className="fa-solid fa-arrow-left mr-2"></i>
                    Geri Dön
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ProjectDetail;
