import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const ShippingSlipAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [stockCards, setStockCards] = useState([]);
  const [receipeStockCards, setReceipeStockCards] = useState([]);
  const [wareHouses, setWareHouses] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState({});
  const [token, setToken] = useState(null);
  //FORM
  const [shippingSlipItems, setShippingSlipItems] = useState([
    {
      stockCardID: Number(),
      unitID: Number(),
      quantity: Number(),
      type: 0,
      status: 0,
      viewIndex: 0,
      description: "",
    },
  ]);
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [unitTypesOpt, setUnitTypesOpt] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [canBeShipped, setCanBeShipped] = useState(false);
  const [exitWareHouse, setExitWareHouse] = useState({});
  const [entranceWareHouse, setEntranceWareHouse] = useState({});

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=38`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataStockCards = res.data.items;
              const newDataStockCards = dataStockCards?.map((item) => ({
                value: item.stockCardID,
                label: `${item.code} - ${item.name}`,
              }));
              setStockCards(newDataStockCards);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/StockCardBOM/GetReceipeStockCards?customerID=${user.country}`
            )
            .then((res) => {
              const dataReceipeStockCards = res.data;
              const newDataReceipeStockCards = dataReceipeStockCards?.map(
                (item) => ({
                  value: item.stockCardID,
                  label: `${item.code} - ${item.name}`,
                })
              );
              setReceipeStockCards(newDataReceipeStockCards);
            })
            .catch((err) => console.log(err.data));
          api
            .get(
              `/api/WorkOrder/GetWorkOrders?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataWorkOrders = res.data.items;
              const newDataWorkOrders = dataWorkOrders?.map((item) => ({
                value: item.workOrderID,
                label: item.name,
              }));
              setWorkOrders(newDataWorkOrders);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetParameters?parametername=UnitType")
            .then((res) => {
              const unitTypes = res.data;
              const newDataUnitTypes = unitTypes?.map((item) => ({
                value: item.parameterID,
                label: item.parameterValue2,
              }));
              setUnitTypesOpt(newDataUnitTypes);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newDataKey = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setKeywordsData(newDataKey);
            })
            .catch((err) => console.log(err));
          api
            .get(
              `/api/WareHouse/GetWareHouses?customerID=${user.country}&pageNumber=1&pageSize=9999`
            )
            .then((res) => {
              console.log(res.data);
              const newDataWareHouses = res.data?.items.map((item) => ({
                value: item.wareHouseID,
                label: item.name,
                type: item.type,
              }));
              setWareHouses(newDataWareHouses);
            })
            .catch((err) => console.log(err));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newDataKey = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setKeywordsData(newDataKey);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleServiceRemove = (index) => {
    const list = [...shippingSlipItems];
    list.splice(index, 1);
    setShippingSlipItems(list);
  };

  const handleServiceAdd = () => {
    setShippingSlipItems([
      ...shippingSlipItems,
      {
        stockCardID: Number(),
        unitID: Number(),
        quantity: Number(),
        type: 0,
        status: 0,
        viewIndex: 0,
        description: "",
      },
    ]);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...shippingSlipItems];
    list[index][name] = value;
    setShippingSlipItems(list);
  };
  const handleSelectChange = (e, index) => {
    const list = [...shippingSlipItems];
    list[index].stockCardID = e;
    setShippingSlipItems(list);
  };
  const handleUnitTypeSelectChange = (e, index) => {
    const list = [...shippingSlipItems];
    list[index].unitID = e;
    setShippingSlipItems(list);
  };

  const postData = {
    customerID,
    exitWareHouseID: exitWareHouse.value,
    entranceWareHouseID: entranceWareHouse.value,
    workOrderID: selectedWorkOrder?.value,
    documentID: 0,
    name,
    title,
    type: 0,
    status: 0,
    viewIndex: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    notes,
    canBeShipped,
    wareHouse_ShippingSlipItems: shippingSlipItems,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.post(
        "/api/WareHouse/PostShippingSlip/PostShippingSlip",
        postData,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Depo transfer fişi oluşturuldu!",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/shippingSlips");
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="flex ">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Depo Transfer Fişleri", href: "/shippingSlips" },
                { label: "Depolar Arası Transfer", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen  w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">
                  Depolar Arası Transfer
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-4 gap-9 items-center w-full"
              >
                <div className="col-span-2">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Başlık :
                  </label>
                  <input
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Adı :
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Giriş Depo :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    options={wareHouses}
                    value={entranceWareHouse}
                    onChange={(e) => setEntranceWareHouse(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                  {entranceWareHouse && entranceWareHouse.type === 1588 && (
                    <div className="flex items-center gap-3 pt-1 absolute">
                      <p className="font-medium text-green-600 pl-2">
                        Sevk Edilebilir
                      </p>
                      <input
                        onChange={(e) => setCanBeShipped(e.target.checked)}
                        checked={canBeShipped}
                        type="checkbox"
                        name=""
                        id=""
                      />
                    </div>
                  )}
                </div>
                <div className="col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Çıkış Depo :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    options={wareHouses}
                    value={exitWareHouse}
                    onChange={(e) => setExitWareHouse(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="keywords"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Anahtar Kelimeler :
                  </label>
                  <CreatableSelect
                    isMulti
                    maxMenuHeight={200}
                    id="keywords"
                    onChange={(e) => setKeywords(e)}
                    options={keywordsData}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    İş Emirleri :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    options={workOrders}
                    value={selectedWorkOrder}
                    onChange={(e) => setSelectedWorkOrder(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="col-span-3">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <textarea
                    id="description"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="notes"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Not :
                  </label>
                  <textarea
                    id="notes"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    rows={4}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>

                <div className="overflow-x-auto mb-5 w-full  col-span-4 mt-12 min-h-[500px]">
                  <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Adet
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Açıklama
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İşlem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {shippingSlipItems?.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-6 py-4">
                            <div className="w-[200px]">
                              <Select
                                onChange={(e) =>
                                  handleSelectChange(e.value, index)
                                }
                                options={stockCards}
                                maxMenuHeight={200}
                                classNamePrefix="custom-select"
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }), // z-index ayarını burada yapın
                                }}
                                placeholder="Seçiniz..."
                              />
                            </div>
                          </td>

                          <td className="px-6 py-4">
                            <div className="w-[200px]">
                              <Select
                                onChange={(e) =>
                                  handleUnitTypeSelectChange(e.value, index)
                                }
                                options={unitTypesOpt}
                                maxMenuHeight={200}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..."
                              />
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.quantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="quantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.description}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="description"
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>

                          <td className="px-6 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-2 px-4 rounded-lg m-5"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <button
                      onClick={handleServiceAdd}
                      type="button"
                      className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg my-5 h-10 w-24"
                    >
                      Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </table>
                </div>

                <div className="col-span-4 flex justify-end gap-[20px]">
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ShippingSlipAdd;
