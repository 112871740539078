import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import Swal from "sweetalert2";
import LoadingSpinnerModal from "../LoadingSpinnerModal";
import { useTranslation } from "react-i18next";

const QuarantineCountModal = ({ onCancel = () => {}, selectedOpenModalId }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(Number());
  const [loading, setLoading] = useState(true);
  const [successQuantity, setSuccessQuantity] = useState(Number());
  const [reProductionQuantity, setReProductionQuantity] = useState(Number());
  const [scrapQuantity, setScrapQuantity] = useState(Number());
  const [remainingQuantity, setRemainingQuantity] = useState(Number());

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/WareHouse/GetShippingSlip?id=${selectedOpenModalId}&customerID=${user.country}`
        )
        .then((res) => {
          setData(res.data);
          setRemainingQuantity(
            res.data?.wareHouse_ShippingSlipItems[0]?.quantity
          );
        })
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);

  useEffect(() => {
    if (data) {
      const totalQuantity =
        Number(successQuantity) +
        Number(reProductionQuantity) +
        Number(scrapQuantity);
      const initialQuantity = data?.wareHouse_ShippingSlipItems[0]?.quantity;

      if (totalQuantity > initialQuantity) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Toplam değer, geliş adedinden fazla olamaz!",
          showConfirmButton: false,
          timer: 1500,
        });
        setSuccessQuantity(0);
        setReProductionQuantity(0);
        setScrapQuantity(0);
      } else {
        setRemainingQuantity(initialQuantity - totalQuantity);
      }
    }
  }, [successQuantity, reProductionQuantity, scrapQuantity, data]);

  const postData = {
    customerID,
    shippingSlipID: data?.shippingSlipID,
    successQuantity,
    reProductionQuantity,
    scrapQuantity,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı.");
      }

      await api.post(
        "/api/WareHouse/PostShippingSlipQuarantina/PostShippingSlipQuarantinatoWareHouses",
        postData,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "İşlem başarılı !",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
      onCancel();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-50 flex justify-center items-center">
      <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
        {loading ? (
          <LoadingSpinnerModal loading={loading} />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-2 gap-2 items-center"
          >
            <div className="col-span-2">
              <h1 className="text-center  text-4xl font-bold leading-none tracking-tight text-blue-800 pb-10">
                Karantina Depodan Gönderim
              </h1>
            </div>
            <div className="col-span-2">
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Başlık :
              </label>
              <input
                type="text"
                id="title"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.wareHouse_ShippingSlipItems[0]?.title}
              />
            </div>
            <div className="col-span-2">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Adı :
              </label>
              <input
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.wareHouse_ShippingSlipItems[0]?.name}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Geliş Adet :
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.wareHouse_ShippingSlipItems[0]?.quantity}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Kalan Adet :
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={remainingQuantity}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Ürün Deposuna Sevk :
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={successQuantity}
                onChange={(e) => setSuccessQuantity(e.target.value)}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Yeniden Üretime Sevk :
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={reProductionQuantity}
                onChange={(e) => setReProductionQuantity(e.target.value)}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Hurdaya Sevk :
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={scrapQuantity}
                onChange={(e) => setScrapQuantity(e.target.value)}
              />
            </div>

            <div className="col-span-2 flex justify-center gap-[20px]">
              <button
                onClick={onCancel}
                className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              >
                Vazgeç
              </button>
              <button
                type="submit"
                className="bg-[#0C692F] hover:bg-[#0c6930d3] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              >
                Gönder
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default QuarantineCountModal;
