import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const WorkOrderContinue = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user && user.country) {
      api
        .get(`/api/WorkOrder/GetWorkOrdersByStatus?customerID=${user.country}&status=1552`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  return (
    <Link to={"/workOrders"}>
      <div className="w-full bg-white border border-gray-200 rounded-lg sm:py-10 cursor-pointer">
        <div className="flow-root">
          <h2 className="text-2xl font-semibold text-center text-blue-700 mb-4">
            Devam Eden İş Emirleri
          </h2>
          <div className="h-60 max-h-60 overflow-y-auto sm:px-10 flex items-center justify-center">
            {data.length === 0 ? (
              <p className="text-lg text-gray-500 font-medium">Henüz devam eden bir iş emri yoktur.</p>
            ) : (
              <ul role="list" className="divide-y divide-gray-200 w-full">
                {data.map((item) => (
                  <li key={item.workOrderID} className="py-3 sm:py-4 border-b-2">
                    <div className="flex items-center">
                      <div className="flex-1 min-w-0 ms-4">
                        <p className="text-lg font-semibold text-gray-600 truncate">
                          {item.name}
                        </p>
                      </div>
                      <div className="flex flex-col gap-3">
                        <p className="text-base text-green-600 font-semibold">
                          Başlangıç Tarihi: <span>{formatDate(item.startDate)}</span>
                        </p>
                        <p className="text-base text-red-600 font-semibold">
                          Bitiş Tarihi: <span>{formatDate(item.endDate)}</span>
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default WorkOrderContinue;
