import React, { createContext, useContext, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import api from "../api/api";
import urls from "../api/urls";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let storedToken = localStorage.getItem("userData");

    if (!storedToken) {
      storedToken = sessionStorage.getItem("userData");
    }

    if (storedToken) {
      const parsed = JSON.parse(storedToken);

      function parseClaimsObject(parsed) {
        const result = {};

        for (const key in parsed) {
          if (key.endsWith("/country")) {
            result.country = parsed[key];
          } else if (key.endsWith("/emailaddress")) {
            result.emailaddress = parsed[key];
          } else if (key.endsWith("/name")) {
            result.name = parsed[key];
          } else if (key.endsWith("/nameidentifier")) {
            result.nameidentifier = parsed[key];
          } else if (key.endsWith("/role")) {
            result.role = parsed[key];
          } else if (key === "exp") {
            result.exp = parsed[key];
          }
        }

        return result;
      }

      const user = parseClaimsObject(parsed);

      // Expired time check
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      if (user.exp && user.exp < currentTime) {
        // Expired, show alert and navigate
        Swal.fire({
          title: "Oturum süresi doldu. Lütfen tekrardan giriş yapınız !",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Tamam",
        }).then((result) => {
          if (result.isConfirmed) {
            logout();
            navigate("/");
          }
        });
      } else {
        // Not expired, set user data
        setUser(user);
      }
    } else {
      // No token found
      setUser(null);
    }
  }, []);

  const parseClaimsObject = (parsed) => {
    const result = {};
    for (const key in parsed) {
      if (key.endsWith("/country")) {
        result.country = parsed[key];
      } else if (key.endsWith("/emailaddress")) {
        result.emailaddress = parsed[key];
      } else if (key.endsWith("/role")) {
        result.role = parsed[key];
      } else if (key.endsWith("/name")) {
        result.name = parsed[key];
      } else if (key.endsWith("/nameidentifier")) {
        result.nameidentifier = parsed[key];
      }
    }
    return result;
  };

  const login = (values, rememberMe, setLoading) => {
    setLoading(true);
    api
      .post(urls.auth, values)
      .then((res) => {
        const token = res.data;
        const decode = jwtDecode(token);
        if (rememberMe) {
          localStorage.setItem("token", token);
          localStorage.setItem("userData", JSON.stringify(decode));
        } else {
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("userData", JSON.stringify(decode));
        }
        const user = parseClaimsObject(decode);
        setUser(user);
        navigate("/dashboards");
      })
      .catch((err) => {
        console.error("Login error:", err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire(translatedError);
      })
      .finally(() => setLoading(false));
  };

  const logout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("token");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
