import React from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import { Link } from "react-router-dom";

const Breadcrumb = ({ breadcrumbData }) => {
  return (
    <nav className="w-full bg-gray-200 h-12 flex justify-between items-center px-16">
      <div className="flex items-center gap-1">
        {/* İlk öğe statik */}
        <Link to={"/dashboards"}>
          <a className="text-[#797a7c] flex items-center gap-2 text-base hover:text-[#4a4b4d] transition-transform duration-300 hover:scale-100 ">
            <AiFillHome size={22} color="#797a7c" />
            <span className="pt-1 font-medium">Anasayfa</span>
          </a>
        </Link>
        <i className="fa-solid fa-chevron-right text-gray-400 mx-2"></i>

        {breadcrumbData.map((item, index) => (
          <React.Fragment key={index}>
            {item.href ? (
              <Link to={item.href}>
                <a className="text-[#797a7c] flex items-center gap-2 text-base hover:text-[#4a4b4d] transition-transform duration-300 hover:scale-100 ">
                  <span className="pt-1 font-medium">{item.label}</span>
                </a>
              </Link>
            ) : (
              <span className="text-[#797a7c] flex items-center gap-2 text-base pointer-events-none">
                <span className="pt-1 font-medium">{item.label}</span>
              </span>
            )}
            {index < breadcrumbData.length - 1 && (
              <i className="fa-solid fa-chevron-right text-gray-400 mx-2"></i>
            )}
          </React.Fragment>
        ))}
      </div>

      <a
      href="https://docs.propexerp.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="inline-block hover:scale-110 transition-transform duration-200 cursor-pointer"
    >
      <BsFillQuestionCircleFill color="#797a7c" size={25} />
    </a>
    </nav>
  );
};

export default Breadcrumb;
