import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import api from "../api/api";
import LoadingSpinner from "./LoadingSpinner";

const EmailConfirm = () => {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const confirmEmail = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const confirmCode = urlParams.get("Confirm");

      if (!confirmCode) {
        setStatus(false);
        setLoading(false);
        return;
      }

      try {
        const response = await api.get(`/api/User/EmailConfirm`, {
          params: { hashCode: confirmCode },
        });
        setStatus(response.data);
      } catch (error) {
        console.error("E-posta doğrulaması sırasında bir hata oluştu:", error);
        setStatus(false);
      } finally {
        setLoading(false);
      }
    };

    confirmEmail();
  }, []);

  if (loading) {
    return <LoadingSpinner loading={loading} />;
  }

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-gray-200 text-gray-800 text-center font-sans p-5">
      <img src={logo} alt="Logo" className="max-w-[80%] h-auto mb-[20px]" />
      {status ? (
        <>
          <h1 className="text-2xl my-2">E-posta Doğrulama Tamamlandı</h1>
          <p className="text-xl mb-5">
            E-posta doğrulamanız başarıyla tamamlandı. Aşağıdaki linkten giriş
            yapabilirsiniz.
          </p>
          <a
            href="/"
            className="text-white bg-[#050A27] hover:bg-[#050a27c7] px-4 py-2 rounded"
          >
            Giriş yap
          </a>
        </>
      ) : (
        <>
          <h1 className="text-2xl my-2">
            Üzgünüz! E-posta Doğrulaması Başarısız
          </h1>
          <p className="text-xl mb-5">
            E-posta adresiniz doğrulanamadı. Lütfen tekrar deneyin.
          </p>
        </>
      )}
    </div>
  );
};

export default EmailConfirm;
