import React from 'react';
import Header from '../components/bars/Header';
import Sidebars from '../components/bars/Sidebar';
import { Link } from 'react-router-dom';
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";

const App = () => {

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        <div className="flex-grow">
          <nav className="bg-gray-200 h-12 flex justify-between items-center px-10 pl-[400px] w-full">
            <div className="flex items-center gap-1">
              {/* İlk öğe statik */}
              <Link to={"/dashboards"}>
                <a className="text-[#797a7c] flex items-center gap-2 text-base hover:text-[#4a4b4d] transition-transform duration-300 hover:scale-100 ">
                  <AiFillHome size={22} color="#797a7c" />
                  <span className="pt-1 font-medium">Anasayfa</span>
                </a>
              </Link>
              <i className="fa-solid fa-chevron-right text-gray-400 mx-2"></i>
            </div>

            <a
              href="https://docs.propexerp.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block hover:scale-110 transition-transform duration-200 cursor-pointer"
            >
              <BsFillQuestionCircleFill color="#797a7c" size={25} />
            </a>
          </nav>
          <div className="m-12 w-full min-h-screen pt-12">
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mt-[-30px]">
              <ul className="flex flex-wrap -mb-px ">
                <li className="me-2">
                  <a href="#">
                    Ön İmalat
                  </a>
                </li>
                <li className="me-2">
                  <a href="#">
                    Üretim Planlama
                  </a>
                </li>
                <li className="me-2">
                  <a href="#">
                    Kapatılan Üretim Planlamaları
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
