import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinnerModal from "../LoadingSpinnerModal";
import { useTranslation } from "react-i18next";

const TaskTransferModal = ({ onCancel = () => {}, taskID }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    if (user && user.country) {
      setUserID(user.nameidentifier);

      api
        .get(`/api/User/GetUsers?customerID=${user.country}`)
        .then((res) => {
          const dataUser = res.data;
          const newData = dataUser?.map((item) => ({
            value: item.userID,
            label: item.userName,
          }));
          setData(newData);
        })
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const postData = {
    taskID,
    transferringUserID: Number(userID),
    transferredUserID: selectedUser.value,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Axios isteği yapılandırma
      await api.put("/api/Task/PutTaskTransfer", postData);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Görev transfer edildi !",
        showConfirmButton: false,
        timer: 1500,
      });
      onCancel();
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinnerModal loading={loading} />
      ) : (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-[1000] flex justify-center items-center">
          <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
            <div className="w-full">
              <label
                htmlFor="title"
                className="block mb-2 text-lg font-medium text-gray-900"
              >
                Transfer edilecek kullanıcıyı seçiniz...
              </label>
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e)}
                maxMenuHeight={200}
                options={data}
                placeholder="Seçiniz..."
              />
            </div>
            <div className="flex justify-center gap-[20px]">
              <button
                onClick={onCancel}
                className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              >
                Vazgeç
              </button>
              <button
                type="button"
                className="bg-[#0C692F] hover:bg-[#0c6930d3] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
                onClick={(e) => handleSubmit(e)}
              >
                Onayla
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskTransferModal;
