import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";

const PersonalChart = () => {
  const { user } = useAuth();
  const [state, setState] = useState({
    series: [
      {
        data: []
      }
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      plotOptions: {
        bar: {
          columnWidth: "20%",
          distributed: true
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "12px"
          }
        }
      },
      yaxis: {
        max: 100
      }
    }
  });

  useEffect(() => {
    if  (user && user.country){

    api
      .get(
        `/api/Report/GetUserTaskSummaryWithScore?customerID=${user.country}&startDate=2024-1-1&endDate=2024-12-31`
      )
      .then((res) => {
        const userNames = res.data.map((item) => item.userName);
        const totalScores = res.data.map((item) => Math.round(item.totalScore));

        setState((prevState) => ({
          ...prevState,
          series: [{ data: totalScores }],
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories: userNames,
              labels: {
                ...prevState.options.xaxis.labels,
                style: {
                  ...prevState.options.xaxis.labels.style,
                  fontWeight: "bold" // Kullanıcı adlarını kalın yazı tipiyle göster
                }
              }
            }
          }
        }));
      })
      .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg">
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default PersonalChart;
