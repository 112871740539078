import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import doc from "../../assets/images/docimg.png";
import { FaStarOfLife } from "react-icons/fa6";

/// REACT SPLIDE
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import LoadingSpinner from "../LoadingSpinner";
import { useTranslation } from "react-i18next";

const WorkOrderClosedDetail = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [token, setToken] = useState(null);
  const [dataImages, setDataImages] = useState(null);
  const [file, setFile] = useState(null);
  //FORM
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [description, setDescription] = useState("");

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=27`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/WorkOrder/GetWorkOrderClosing?customerID=${user.country}&id=${params.id}`
            )
            .then((res) => {
              setData(res.data);
              setEndDate(new Date(res.data.endDate));
              setStartDate(new Date(res.data.startDate));
              setDescription(res.data.description);
              setDataImages(res.data.imageFiles);
            })
            .catch((err) => console.log(err));

          api
            .get(
              `/api/WorkOrder/GetWorkOrderClosingItems?customerID=${user.country}&workOrderClosingID=${params.id}`
            )
            .then((res) => {
              const dataItem = res.data;
              const newDataItem = dataItem.map((item) => ({
                workOrderItemID: item.workOrderItemID,
                name: item.name,
                startDate: new Date(item.startDate),
                endDate: new Date(item.startDate),
                plannedProductionQuantity: item.plannedProductionQuantity,
                productionQuantity: item.productionQuantity,
                description: item.description,
                machineName: item?.machineName,
                operationName: item?.operationName,
              }));
              setDataItems(newDataItem);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  console.log(dataItems);

  // FILE ONCHANGE
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const handleDocSubmit = async (e) => {
    setLoading(true);

    const formData = new FormData();
    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Dosyaları yükle (eğer varsa)
      if (file && file.length > 0) {
        await api.post(
          `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=1584&relationID=${params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Dosyalar yüklendi !",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
      // Belirli bir süre sonra sayfa yenilensin
      setTimeout(() => {
        window.location.reload();
      }, 1500); // 1500 milisaniye (1.5 saniye) sonra yenile
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="p-12 mx-auto mt-28 mb-20 rounded-[3px] min-h-screen w-[75%] bg-gray-100">
            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title ">{data?.name}</h1>
            </div>
            <form className="grid grid-cols-4 gap-7 items-center w-full">
              <div className="col-span-4">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  İş Emri Adı :
                </label>
                <input
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  disabled
                  value={data?.name}
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Baş. Tarih :
                </label>
                <DatePicker
                  id="startDate"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  showTimeSelect
                  locale={tr}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  disabled
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Bit. Tarih :
                </label>
                <DatePicker
                  id="startDate"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  showTimeSelect
                  locale={tr}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  disabled
                />
              </div>

              <div className="col-span-2">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled
                />
              </div>
              <div className="col-span-2 flex items-center">
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                  id="multiple_files"
                  type="file"
                  multiple
                  onChange={handleChangeFile}
                />
                <button
                  type="button"
                  className="bg-gray-900  hover:bg-gray-700  text-white font-bold py-2 px-4"
                  onClick={() => handleDocSubmit()}
                >
                  Yükle
                </button>
              </div>
              <div className="col-span-4 py-12">
                <Splide
                  options={{
                    autoWidth: true,
                    gap: 100,
                    pagination: false,
                    padding: 100,
                  }}
                  aria-label="My Favorite Images"
                >
                  {dataImages?.map((item, index) => {
                    const imageName = item.split("/").pop();
                    const altText = imageName.split("_").pop().split(".")[0];
                    // Resim URL'si kontrolü
                    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(item);
                    return (
                      <SplideSlide key={index}>
                        {isImage ? (
                          <Link to={item}>
                            <img
                              className="max-w-[200px] cursor-pointer"
                              src={item}
                              alt={`Image ${index + 1}`}
                            />
                          </Link>
                        ) : (
                          <Link to={item}>
                            <img
                              className="max-w-[100px] cursor-pointer"
                              src={`${doc}`}
                            />
                          </Link>
                        )}
                        <p className="text-center font-medium">{altText}</p>
                      </SplideSlide>
                    );
                  })}
                </Splide>
              </div>

              <div className="relative overflow-x-auto mb-5 col-span-4 mt-12">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-[#050A27]">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Ürün
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Makine/Operasyon
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Baş.Tarih
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Bit.Tarih
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Planlanan
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Gerçekleşen
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Açıklama
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataItems?.map((singleService, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="px-6 py-4">
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            disabled
                            value={singleService.name}
                          />
                        </td>

                        <td className="px-6 py-4">
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            disabled
                            value={
                              singleService?.machineName ||
                              singleService?.operationName
                            }
                          />
                        </td>
                        <td className="px-6 py-4">
                          <DatePicker
                            id="startDate"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            showTimeSelect
                            locale={tr}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            placeholderText="Tarih seçiniz..."
                            selected={singleService.startDate}
                            disabled
                          />
                        </td>
                        <td className="px-6 py-4">
                          <DatePicker
                            id="endDate"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            showTimeSelect
                            locale={tr}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            placeholderText="Tarih seçiniz..."
                            selected={singleService.endDate}
                            disabled
                          />
                        </td>
                        <td className="px-6 py-4">
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            value={singleService.plannedProductionQuantity}
                            disabled
                          />
                        </td>
                        <td className="px-6 py-4">
                          <input
                            name="actualProductionQuantity"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            disabled
                            value={singleService.productionQuantity}
                          />
                        </td>
                        <td className="px-6 py-4">
                          <input
                            name="description"
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Açıklama"
                            value={singleService.description}
                            disabled
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default WorkOrderClosedDetail;
