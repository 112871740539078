import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import AddProduct from "./AddProduct";
import PutProduct from "./PutProduct";
import DeleteProduct from "./DeleteProduct";
import AddAccount from "./accounts/AddAccount";
import PutAccount from "./accounts/PutAccount";
import DeleteAccount from "./accounts/DeleteAccount";

const SideBarRight = ({ onCancel = () => {} }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openPutModal, setOpenPutModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAccountAddModal, setOpenAccountAddModal] = useState(false);
  const [openAccountPutModal, setOpenAccountPutModal] = useState(false);
  const [openAccountDeleteModal, setOpenAccountDeleteModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Bileşen açıldığında sidebar'ı açmak için
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 0);
  }, []);

  // Sidebar'ı kapatma işlemi (animasyonlu)
  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      onCancel(); // Animasyon tamamlandıktan sonra kapatma işlemi
    }, 500); // 500ms animasyon süresi ile eşleştirildi
  };

  return (
    <>
      <div
        className={`absolute top-0 right-0 w-[200px] h-full bg-gray-50 z-[1000] flex flex-col gap-8 border-l border-gray-300 items-end
        transform transition-transform duration-500 ease-in-out ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div>
          <div className="text-3xl float-right pt-2">
            <AiOutlineCloseCircle
              color="black"
              cursor="pointer"
              onClick={handleClose}
              className="hover:scale-110 transition-transform duration-200"
            />
          </div>
          <div className="flex flex-col mt-[50px] gap-5 mr-5">
            <h1 className="border-b border-gray-300 text-lg font-medium text-black">
              Ürünler
            </h1>
            <button
              onClick={() => setOpenAddModal(true)}
              type="button"
              className="text-white bg-[#0C692F] hover:bg-[#0c6930d3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Ürün Ekle
            </button>
            <button
              onClick={() => setOpenPutModal(true)}
              type="button"
              className="text-white bg-[#0C692F] hover:bg-[#0c6930d3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Ürün Güncelle
            </button>
            <button
              onClick={() => setOpenDeleteModal(true)}
              type="button"
              className="text-white bg-[#0C692F] hover:bg-[#0c6930d3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Ürün Sil
            </button>
            <h1 className="border-b border-gray-400 text-[17px] font-medium text-black">
              Cariler
            </h1>
            <button
              onClick={() => setOpenAccountAddModal(true)}
              type="button"
              className="text-white bg-[#050A27] hover:bg-[#050a27ce] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Cari Ekle
            </button>
            <button
              onClick={() => setOpenAccountPutModal(true)}
              type="button"
              className="text-white bg-[#050A27] hover:bg-[#050a27ce] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Cari Güncelle
            </button>
            <button
              onClick={() => setOpenAccountDeleteModal(true)}
              type="button"
              className="text-white bg-[#050A27] hover:bg-[#050a27ce] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Cari Sil
            </button>
          </div>
        </div>
      </div>
      
      {openAddModal && <AddProduct onCancel={() => setOpenAddModal(false)} />}
      {openPutModal && <PutProduct onCancel={() => setOpenPutModal(false)} />}
      {openDeleteModal && <DeleteProduct onCancel={() => setOpenDeleteModal(false)} />}
      {openAccountAddModal && <AddAccount onCancel={() => setOpenAccountAddModal(false)} />}
      {openAccountPutModal && <PutAccount onCancel={() => setOpenAccountPutModal(false)} />}
      {openAccountDeleteModal && <DeleteAccount onCancel={() => setOpenAccountDeleteModal(false)} />}
    </>
  );
};

export default SideBarRight;
