import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import "./Timeline.css";
import Timeline, {
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import api from "../../api/api";
import moment from "moment";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import Breadcrumb from "../../components/bars/Breadcrumb";

const ShiftDetails = () => {
  const { user } = useAuth();
  const params = useParams();
  const [machines, setMachines] = useState([]);
  const [operations, setOperations] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);

      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=26`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Machine/GetMachines?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const newFormattedMachineShifts = res.data.items.map(
                (machine) => ({
                  id: `machine_${machine.machineID}`,
                  title: (
                    <>
                      <i className="fa-solid fa-gears text-red-600"></i>{" "}
                      {machine.name}
                    </>
                  ),
                  group: `machine_${machine.machineID}`, // Makine grupları için farklı bir ID oluştur
                })
              );
              setMachines(newFormattedMachineShifts);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Operation/Getoperations?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const newFormattedOperationShifts = res.data.items.map(
                (operation) => ({
                  id: `operation_${operation.operationID}`,
                  title: (
                    <>
                      <i className="fa-solid fa-person-digging text-green-600"></i>{" "}
                      {operation.name}
                    </>
                  ),
                  group: `operation_${operation.operationID}`, // Operasyon grupları için farklı bir ID oluştur
                })
              );
              setOperations(newFormattedOperationShifts);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Shift/GetShiftPlaningDetail?customerID=${user.country}&shiftID=${params.id}`
            )
            .then((res) => {
              const newFormattedShifts = res.data.map((item) => {
                let groupID;

                if (item.machineID) {
                  groupID = `machine_${item.machineID}`;
                } else if (item.operationID) {
                  groupID = `operation_${item.operationID}`;
                }

                return {
                  id: item.shiftDetailID,
                  group: groupID,
                  title: item.workOrderDetails?.name, // Dize olarak işlenmemiş
                  start_time: moment(item.partStartTime).toDate(),
                  end_time: moment(item.partEndTime).toDate(),
                  workOrderDetails: item.workOrderDetails,
                  canMove: false,
                  canResize: false,
                  canChangeGroup: false,
                };
              });
              setShifts(newFormattedShifts);
            })
            .catch((err) => console.log(err.message))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  const handleItemClick = (itemId) => {
    const selectedItem = shifts.find((item) => item.id === itemId);
    console.log(selectedItem);
    if (selectedItem) {
      Swal.fire({
        title: `${selectedItem.title}`,
        html: `Baş.Tarihi:<span class="font-bold text-green-600"> ${formatDate(
          selectedItem.workOrderDetails.startDate
        )}</span><br>Bit.Tarih: <span class="font-bold text-green-600">${formatDate(
          selectedItem.workOrderDetails.endDate
        )}</span><br> Planlanan Adet: <span class="font-bold text-green-600">${
          selectedItem.workOrderDetails.plannedProductionQuantity
        }</span><br> Gerçekleşen Adet: <span class="font-bold text-green-600">${
          selectedItem.workOrderDetails.actualProductionQuantity
        }</span>`,
      });
    }
  };

  const firstShiftStartTime =
    shifts.length > 0
      ? shifts[0].start_time
      : moment().add(-12, "hour").toDate();

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
          <Breadcrumb
            breadcrumbData={[
              { label: "Vardiya Takvimi", href: "/personelShifts" },
              { label: "Vardiya Detayı", href: "" },
            ]}
          />
          <div className="mt-12 mx-auto min-h-screen w-[75vw] ">
            <div>
              <p className="font-semibold text-green-600 text-lg">
                Lütfen vardiyaları daha net görebilmek için başlık alanına{" "}
                <span className="text-gray-500">▼</span> tıklayınız !
              </p>
            </div>
            <div className="w-full overflow-y-scroll max-h-[75vh]">
              <Timeline
                groups={[...machines, ...operations]}
                items={shifts}
                defaultTimeStart={moment().add(-12, "hour")}
                defaultTimeEnd={moment().add(12, "hour")}
                lineHeight={50}
                itemHeightRatio={0.9}
                onItemClick={(id) => handleItemClick(id)}
              >
                <TimelineMarkers>
                  <TodayMarker />
                </TimelineMarkers>
              </Timeline>
            </div>
          </div>
    </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ShiftDetails;
