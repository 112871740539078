import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';

const LoadingSpinner = ({ loading }) => {
  return (
    <div className="grid place-items-center h-screen w-screen">
      <MoonLoader
        color={"#020617"}
        loading={loading}
        size={70}
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={0.5}
      />
    </div>
  );
};

export default LoadingSpinner;