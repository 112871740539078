
import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';

const options = [
  {
    value: '1',
    label: 'Muhasebe',
  },
  {
    value: '2',
    label: 'Sevkiyat',
  },
  {
    value: '3',
    label: 'Sistem Yöneticisi',
  },

];

function Select({ value, handleId, nodeId }) {

  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (evt) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            selects: {
              ...node.data.selects,
              [handleId]: evt.target.value,
            },
          };
        }

        return node;
      })
    );
  };

  return (
    <div className="custom-node__select">
      <Handle style={{top:"24px", left:"-15px",width:"6px", height:"10px",borderRadius:"2px",backgroundColor:"#778899"}} type='target' position={Position.Left} id={handleId}/>
      <div style={{fontSize:"12px", fontWeight:"500"}}>Seçiniz</div>
      <select className="nodrag" onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <Handle style={{top:"24px", right:"-15px",width:"6px", height:"10px",borderRadius:"2px",backgroundColor:"#778899"}} type="source" position={Position.Right} id={handleId} />
    </div>
  );
}

function CustomNodeMail({ id, data }) {
  return (
    <>
   <div style={{backgroundColor:"#f5f5f6",borderRadius:"10px"}}>
   <div className="custom-node__header">
        <strong>{data.label}</strong>
      </div>
      <div className="custom-node__body">
        {Object.keys(data.selects).map((handleId) => (
          <Select key={handleId} nodeId={id} value={data.selects[handleId]} handleId={handleId} />
        ))}
      </div>
   </div>
    </>
  );
}

export default memo(CustomNodeMail);









// import React, { memo, useState } from "react";
// import { Handle, Position } from "reactflow";

// function CustomNodeMail({ data, isConnectable }) {
//   const [selectedOption, setSelectedOption] = useState("");
//   const [selectedOption2, setSelectedOption2] = useState("");

//   // Seçeneklerin bulunduğu dizi
//   const options2 = ["Muhasebe", "Sevkiyat"];

//   // Select değiştiğinde çağrılacak fonksiyon

//   const handleSelectChange2 = (event) => {
//     const value = event.target.value;
//     setSelectedOption2(value);
//   };
//   return (
//     <>
//       <div className="custom-action">
//         <Handle
//           type="target"
//           position={Position.Left}
//           style={{ background: "#555" }}
//           onConnect={(params) => console.log("handle onConnect", params)}
//           isConnectable={isConnectable}
//         />
//         <div style={{ textAlign: "center", margin: "7px 0" }}>
//           <h1 style={{ fontWeight: "500", fontSize: "20px" }}>{data.label}</h1>
//         </div>

//         <div className="mt-6">
//           <h2 style={{ fontWeight: "500", fontSize: "13px" }}>Departman:</h2>
//           <select
//             id="selectOptions"
//             onChange={handleSelectChange2}
//             value={selectedOption2}
//             className="w-full text-center"
//             style={{ fontSize: "15px", marginTop: "5px" }}
//           >
//             <option value="">Seçiniz</option>
//             {options2.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         </div>

//         <Handle
//           type="source"
//           position={Position.Right}
//           id="a"
//           style={{ background: "#555" }}
//           isConnectable={isConnectable}
//         />
//       </div>
//     </>
//   );
// }

// export default memo(CustomNodeMail);






// export default memo(({ data, isConnectable }) => {
//   const [selectedOption, setSelectedOption] = useState("");
//   const [selectedOption2, setSelectedOption2] = useState("");

//   // Seçeneklerin bulunduğu dizi
//   const options2 = ["Muhasebe", "Sevkiyat"]

//   // Select değiştiğinde çağrılacak fonksiyon

//   const handleSelectChange2 = (event) => {
//     const value = event.target.value;
//     setSelectedOption2(value);
//   };
//   return (
//     <>
//     <div className="custom-action">
//       <Handle
//         type="target"
//         position={Position.Left}
//         style={{ background: "#555" }}
//         onConnect={(params) => console.log("handle onConnect", params)}
//         isConnectable={isConnectable}
//       />
//       <div style={{ textAlign: "center", margin: "7px 0" }}>
//         <h1 style={{ fontWeight: "500", fontSize: "20px" }}>{data.label}</h1>
//       </div>

//       <div className="mt-6">
//         <h2 style={{ fontWeight: "500", fontSize: "13px" }}>Departman:</h2>
//         <select
//           id="selectOptions"
//           onChange={handleSelectChange2}
//           value={selectedOption2}
//           className="w-full text-center"
//           style={{ fontSize: "15px", marginTop: "5px" }}
//         >
//           <option value="">Seçiniz</option>
//           {options2.map((option, index) => (
//             <option key={index} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>
//       </div>

//       <Handle
//         type="source"
//         position={Position.Right}
//         id="a"
//         style={{ background: "#555" }}
//         isConnectable={isConnectable}
//       />
//     </div>
//   </>
//   );
// });

// import React, { memo } from 'react';
// import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';

// const options = [
//   {
//     value: 'smoothstep',
//     label: 'Smoothstep',
//   },
//   {
//     value: 'step',
//     label: 'Step',
//   },
//   {
//     value: 'default',
//     label: 'Bezier (default)',
//   },
//   {
//     value: 'straight',
//     label: 'Straight',
//   },
// ];

// function Select({ value, handleId, nodeId }) {
//   const { setNodes } = useReactFlow();
//   const store = useStoreApi();

//   const onChange = (evt) => {
//     const { nodeInternals } = store.getState();
//     setNodes(
//       Array.from(nodeInternals.values()).map((node) => {
//         if (node.id === nodeId) {
//           node.data = {
//             ...node.data,
//             selects: {
//               ...node.data.selects,
//               [handleId]: evt.target.value,
//             },
//           };
//         }

//         return node;
//       })
//     );
//   };

//   return (
//     <div className="custom-node__select">
//       <div>Edge Type</div>
//       <select className="nodrag" onChange={onChange} value={value}>
//         {options.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//       <Handle position={Position.Right} id={handleId} />
//     </div>
//   );
// }

// function CustomNode({ id, data }) {
//   return (
//     <>
//       <div className="custom-node__header">
//         This is a <strong>custom node</strong>
//       </div>
//       <div className="custom-node__body">
//         {Object.keys(data.selects).map((handleId) => (
//           <Select key={handleId} nodeId={id} value={data.selects[handleId]} handleId={handleId} />
//         ))}
//       </div>
//     </>
//   );
// }

// export default memo(CustomNode);
