import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const tokenLocal = localStorage.getItem('token');
  const tokenSession = sessionStorage.getItem('token');

  useEffect(() => {
    // Eğer ekran genişliği 768 pikselin altındaysa kullanıcıya uyarı göster
    if (window.innerWidth < 768) {
      Swal.fire({
        icon: 'warning',
        title: 'Mobil cihaz desteklenmiyor',
        text: 'Lütfen bu uygulamaya masaüstü cihazdan erişin.',
        confirmButtonText: 'Tamam',
      });
    }
  }, []);

  // Ekran genişliği küçükse veya token yoksa anasayfaya yönlendir
  if (window.innerWidth < 768 || !(tokenLocal || tokenSession)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;
