import React, { useState } from "react";
import Swal from "sweetalert2";
import api from "../../api/api";
import LoadingSpinnerModal from "../LoadingSpinnerModal";

const StockCardCopyModal = ({
  onCancel = () => {},
  onSubmitCancel = () => {},
  data,
}) => {
  const [name, setName] = useState(data?.name);
  const [code, setCode] = useState(data?.code);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Aynı veriyi gönderme kontrolü
    if (name === data?.name && code === data?.code) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Aynı veriyi gönderemezsiniz!",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    setLoading(true);

    try {
      const queryParams = `customerID=${data?.customerID}&stockCardID=${data?.stockCardID}&name=${name}&code=${code}`;
      await api.post(`/api/StockCard/PostCopy/PostCopy?${queryParams}`);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Başarıyla kopyalandı!",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        onCancel();
        onSubmitCancel();
      });
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinnerModal loading={loading} />
      ) : (
        <div className="fixed inset-0 w-screen h-screen bg-black bg-opacity-30 z-50 flex justify-center items-center">
          <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-4 gap-7 items-center"
            >
              <div className="col-span-4">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Ürün Adı :
                </label>
                <input
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-span-4">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Ürün Kodu :
                </label>
                <input
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div className="col-span-4 flex justify-center gap-[20px]">
                <button
                  onClick={onCancel}
                  className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
                >
                  Vazgeç
                </button>
                <button
                  type="submit"
                  className="bg-[#0C692F] hover:bg-[#0c6930d3] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
                >
                  Onayla
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default StockCardCopyModal;
