import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const WorkOrderDetailPdf = ({ logoStr, dataItems, data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontFamily: "Roboto",
    },
    section: {
      marginBottom: 10,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    offerNoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 20,
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    logo: {
      width: 140, // Adjust width and height to fit your logo
      height: 40,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
    },
    table: {
      marginBottom: 20,
    },
    tableHeader: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      borderBottomStyle: "solid",
      marginBottom: 5,
    },
    tableRow: {
      flexDirection: "row",
      marginBottom: 5,
      borderBottomColor: "gray",
      borderBottomStyle: "solid",
      borderBottomWidth: 0.5,
    },
    tableCell: {
      flex: 1,
      fontSize: 10,
      padding: 5,
    },
    footer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 20,
    },
    description: {
      fontSize: 10,
      fontWeight: "bold",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
      fontFamily: "Roboto",
    },
    logoPlaceholder: {
      fontSize: 20,
      color: "grey",
      fontWeight: "bold",
      textAlign: "center",
    },
  });
  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    const saat = tarihObjesi.getHours();
    const dakika = tarihObjesi.getMinutes();

    // Gün, ay ve dakikanın tek haneli olması durumunda başlarına sıfır eklenmesi
    const formattedGun = gun < 10 ? `0${gun}` : gun;
    const formattedAy = ay < 10 ? `0${ay}` : ay;
    const formattedSaat = saat < 10 ? `0${saat}` : saat;
    const formattedDakika = dakika < 10 ? `0${dakika}` : dakika;

    return `${formattedGun}/${formattedAy}/${yil} ${formattedSaat}:${formattedDakika}`;
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            {logoStr ? (
              <Image src={logoStr} style={styles.logo} />
            ) : (
              <Text style={styles.logoPlaceholder}>LOGO</Text>
            )}
          </View>
          <View style={styles.offerNoContainer}>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{data?.name} </Text>
            </View>
            <Text style={styles.text}>
              Başlangıç Tarih: {formatDate(data?.startDate)}
            </Text>
            <Text style={styles.text}>
              Bitiş Tarihi: {formatDate(data?.endDate)}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.text}>Açıklama: {data?.description}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableCell}>Ürün Adı</Text>
            <Text style={styles.tableCell}>Makine/Operasyon</Text>
            <Text style={styles.tableCell}>Baş. Tarih:</Text>
            <Text style={styles.tableCell}>Bit. Tarih:</Text>
            <Text style={styles.tableCell}>Planlanan</Text>
            <Text style={styles.tableCell}>Gerçekleşen</Text>
            <Text style={styles.tableCell}>Açıklama</Text>
          </View>
          {dataItems?.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{item?.name}</Text>
              <Text style={styles.tableCell}>
                {item?.machineName || item?.operationName}
              </Text>
              <Text style={styles.tableCell}>
                {formatDate(item?.startDate)}
              </Text>
              <Text style={styles.tableCell}>{formatDate(item?.endDate)}</Text>
              <Text style={styles.tableCell}>
                {item?.plannedProductionQuantity}
              </Text>
              <Text style={styles.tableCell}>
                {item?.actualProductionQuantity}
              </Text>
              <Text style={styles.tableCell}>{item?.description}</Text>
            </View>
          ))}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default WorkOrderDetailPdf;
