import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Select from "react-select";
import { FaStar } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import { useTranslation } from "react-i18next";

const QualityFormsCheck = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [customerID, setCustomerID] = useState(Number());
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const params = useParams();
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [token, setToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1; // Number of items to show per page
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shippingSlipID = queryParams.get("shippingSlipID");

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=37`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/FormBuilder/GetFormsByStockCardID?stockCardID=${params.id}&customerID=${user.country}`
            )
            .then((res) => setData(res.data))
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true); // İşleme başlarken yüklenme durumunu true yapar

    try {
      const response = await api.post(
        `/api/WareHouse/PostShippingSlipQualityControl/PostShippingSlipQualityControl?shippingSlipID=${shippingSlipID}&customerID=${customerID}`
      );

      // Başarılı işlem sonrası yapılacaklar
      console.log("Data saved successfully:", response.data);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "İşlem başarılı!",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/shippingSlips");
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false); // İşlem tamamlandıktan sonra yüklenme durumunu false yapar
    }
  };

  const handleChange = (fieldID, value) => {
    setFormData((prev) => ({ ...prev, [fieldID]: value }));
  };
  console.log(data);
  console.log("shippingSlipID", shippingSlipID);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="min-h-screen">
            {currentItems.map((formDataItem) => (
              <form
                key={formDataItem.id}
                className="m-12 w-[80vw] h-[90vh] p-8 bg-gray-100 rounded-lg flex flex-col gap-2 overflow-y-scroll relative"
              >
                <h1 className="text-center font-medium text-2xl">
                  {formDataItem.name}
                </h1>
                <p className="mb-[50px] mt-[50px]">
                  <span className="font-medium mr-[5px]">Açıklama:</span>{" "}
                  {formDataItem.description}
                </p>
                {formDataItem.formFields.map((field) => (
                  <div className="mb-[20px]" key={field.formFieldID}>
                    <label
                      className="font-medium mr-[5px]"
                      htmlFor={`field-${field.formFieldID}`}
                    >
                      {field.name}:
                    </label>
                    {getFieldInputByType(
                      field,
                      handleChange,
                      setRating,
                      rating,
                      hover,
                      setHover
                    )}
                  </div>
                ))}
                {/* Save button at the bottom right */}
                {currentPage === totalPages && (
                  <button
                    onClick={handleSave}
                    type="button"
                    className="absolute bottom-5 right-5 px-4 py-2 bg-green-500 text-white rounded-md border-none cursor-pointer"
                  >
                    Kaydet
                  </button>
                )}
              </form>
            ))}
            <div className="text-center mt-5 pb-[50px]">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className={`px-8 py-2 mx-1 rounded-md border-none cursor-pointer ${
                  currentPage === 1
                    ? "bg-blue-500 text-white opacity-50 cursor-not-allowed"
                    : "bg-blue-600 text-white hover:bg-blue-700"
                }`}
              >
                Geri
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={`px-5 py-2 mx-1 rounded-md border-none cursor-pointer ${
                    currentPage === index + 1
                      ? "bg-blue-600 text-white"
                      : "bg-gray-300 text-black"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`px-7 py-2 mx-1 rounded-md border-none ${
                  currentPage === totalPages
                    ? "bg-blue-600 text-white cursor-not-allowed"
                    : "bg-blue-600 text-white cursor-pointer"
                }`}
              >
                İleri
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

// Helper function to get input by field type
const getFieldInputByType = (
  field,
  handleChange,
  setRating,
  rating,
  hover,
  setHover
) => {
  switch (field.type) {
    case 93:
      return (
        <input
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5"
          type="text"
          id={`field-${field.formFieldID}`}
          onChange={(e) => handleChange(field.formFieldID, e.target.value)}
        />
      );
    case 94:
      return (
        <textarea
          className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 h-40 p-2.5 resize-none"
          id={`field-${field.formFieldID}`}
          onChange={(e) => handleChange(field.formFieldID, e.target.value)}
        ></textarea>
      );
    case 95:
      return <input type="radio" id={`field-${field.formFieldID}`} />;
    case 96:
      return <input type="checkbox" id={`field-${field.formFieldID}`} />;
    case 99:
      return (
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5"
          type="date"
          id={`field-${field.formFieldID}`}
          onChange={(e) => handleChange(field.formFieldID, e.target.value)}
        />
      );
    case 100:
      return <input type="file" id={`field-${field.formFieldID}`} />;
    case 97:
      const selectOptions = field.value
        .split(",")
        .map((word) => ({ value: word.trim(), label: word.trim() }));
      return (
        <Select
          options={selectOptions}
          className="w-48 my-1"
          placeholder={"Seçiniz"}
          maxMenuHeight={200}
          id={`field-${field.formFieldID}`}
          onChange={(option) => handleChange(field.formFieldID, option?.value)}
        />
      );
    case 98:
      return (
        <div className="flex gap-2" id={`field-${field.formFieldID}`}>
          {[...Array(5)].map((star, index) => {
            const currentRating = index + 1;

            return (
              <label key={currentRating}>
                <input
                  className="hidden"
                  type="radio"
                  name={`rating-${field.formFieldID}`}
                  value={currentRating}
                  onClick={() => setRating(currentRating)}
                />
                <FaStar
                  className="cursor-pointer"
                  size={30}
                  color={
                    currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"
                  }
                  onMouseEnter={() => setHover(currentRating)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </div>
      );
    default:
      return null;
  }
};

export default QualityFormsCheck;
