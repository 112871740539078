import React, { useEffect } from "react";
import "../home/home.css";
import DashboardChart from "../../components/views/DashboardChart";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import ColumnChart from "../../components/views/ColumnChart";
import WelcomeCard from "../../components/views/WelcomeCard";
import AssignmentCard from "../../components/views/AssignmentCard";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getUser } from "../../redux/userDataSlice";
import PersonalChart from "../../components/views/PersonalChart";
import TaskGraph from "../../components/views/TaskGraph";
import WorkOrdersGraph from "../../components/views/WorkOrdersGraph";
import WorkOrderContinue from "../../components/views/WorkOrderContinue";
import LoadingSpinner from "../LoadingSpinner";
import { AiFillHome } from "react-icons/ai";
import { BsFillQuestionCircleFill } from "react-icons/bs";

const Home = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getNotification());
  }, []);

  return (
    <>
      <Header />
      <div className="flex min-h-screen">
        <Sidebars />
        {data.isLoading ? (
          <LoadingSpinner loading={data.isLoading} />
        ) : (
          <div className="flex-grow">
            <div className="w-full bg-gray-100 h-12 flex justify-between items-center px-20">
              <a
                className="text-[#797a7c] flex items-center gap-2"
                href="/dashboards"
              >
                <div className=" flex items-center gap-2">
                  <AiFillHome size={22} color="#797a7c" />
                  <span className="pt-1 font-medium cursor-pointer hover:text-[#4a4b4d] transition-transform duration-300 hover:scale-100">
                    Anasayfa
                  </span>
                </div>
              </a>
              <a
                href="https://docs.propexerp.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block hover:scale-110 transition-transform duration-200 cursor-pointer pr-3"
              >
                <BsFillQuestionCircleFill color="#797a7c" size={25} />
              </a>
            </div>
            <div className="grid md:gap-7 md:grid-cols-2 lg:grid-cols-4 lg:gap-7 items-center mt-10 mx-auto w-[90%]">
              <div className="col-span-2">
                <WelcomeCard data={data} />
              </div>
              <div className="col-span-2">
                <AssignmentCard />
              </div>
              <div className="col-span-2">
                <TaskGraph />
              </div>
              <div className="col-span-2">
                <DashboardChart />
              </div>
              <div className="col-span-2">
                <PersonalChart />
              </div>
              <div className="col-span-2">
                <ColumnChart />
              </div>
              <div className="col-span-2 mb-7">
                <WorkOrdersGraph />
              </div>
              <div className="col-span-2 mb-7">
                <WorkOrderContinue />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
