import React from "react";
import MoonLoader from "react-spinners/MoonLoader";

const LoadingSpinnerModal = ({ loading }) => {
  return (
    <div className="grid place-items-center h-full w-full">
      <MoonLoader
        color={"#020617"}
        loading={loading}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={0.5}
      />
    </div>
  );
};

export default LoadingSpinnerModal;
