import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import CreatableSelect from "react-select/creatable";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "../../../assets/images/location.png";
import api from "../../../api/api";
import Swal from "sweetalert2";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const AddAccount = ({ onCancel = () => {} }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [customerID, setCustomerID] = useState(Number());
  //FORM STATE
  const [name, setName] = useState("");
  const [taxOffice, setTaxOffice] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [postCode, setPostCode] = useState("");
  const [description, setDescription] = useState("");
  const [customerKey, setCustomerKey] = useState([]);

  //FOR SELECT BOX OPTIONS
  const [getSector, setGetSector] = useState([]);
  const [getCountry, setGetCountry] = useState([]);
  const [getCity, setGetCity] = useState([]);
  const [getDistrict, setGetDistrict] = useState([]);
  const [getStatus, setGetStatus] = useState([]);
  const [getType, setGetType] = useState([]);

  //SELECT VALUE STATES
  const [sector, setSector] = useState({});
  const [country, setCountry] = useState({});
  const [city, setCity] = useState({});
  const [district, setDistrict] = useState({});
  const [status, setStatus] = useState({});
  const [type, setType] = useState({});
  const [keywords, setKeywords] = useState([]);

  //MAP STATE
  const [latLng, setLatLng] = useState({});

  useEffect(() => {
    setToken(localStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=7`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get("/api/Common/GetParameters?parametername=Sector")
            .then((res) => {
              setGetSector(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=Country")
            .then((res) => {
              setGetCountry(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=City")
            .then((res) => {
              setGetCity(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=District")
            .then((res) => {
              setGetDistrict(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=AccountStatus")
            .then((res) => {
              setGetStatus(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=AccountType")
            .then((res) => {
              setGetType(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  // REACT-LEAFLET
  const handleClick = (e) => {
    const { lat, lng } = e.latlng;
    setLatLng({ lat, lng });
  };

  const MapComponent = () => {
    const map = useMapEvents({
      click: handleClick,
    });

    return null;
  };
  const ozelIcon = new L.Icon({
    iconUrl: icon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const districtChange = (e) => {
    setLoading(true);
    setCity(e);
    api
      .get(`/api/Common/GetDistricts?city=${e.parameterValue1}`)
      .then((res) => {
        setGetDistrict(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => setLoading(false));
  };

  //// SELECT OPTIONS /////////
  const sectorOptions = getSector?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const countryOptions = getCountry?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const cityOptions = getCity?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
    parameterValue1: item.parameterValue1,
  }));

  const districtOptions = getDistrict?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const statusOptions = getStatus?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const typeOptions = getType?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  // TO STRING FUNCTION
  const stringKeywords = keywords?.map((item) => item.label);

  const postData = {
    customerID,
    name,
    contactName,
    taxOffice,
    taxNumber,
    address,
    email,
    webUrl,
    postCode,
    telephone,
    cellphone,
    description,
    sector: sector.value,
    countryID: country.value,
    cityID: city.value,
    districtID: district.value,
    status: status.value,
    type: type.value,
    keywords: JSON.stringify(stringKeywords),
    latLng: JSON.stringify(latLng),
    ImageFile: "",
    Notes: "",
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .post("/api/Account/Post/Post", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Cari oluşturuldu !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/accounts");
      })

      .catch((err) => {
        console.log(err);
        Swal.fire("Cari Oluşturalamadı !");
      })
      .finally(() => setLoading(false));
  };
  console.log(">>>", keywords);
  return (
    <div className="fixed inset-0 w-screen h-screen bg-black bg-opacity-30 z-[1000] flex justify-center items-center">
      <div className="w-4/5 p-12 bg-white rounded-lg flex flex-col justify-center items-center overflow-y-scroll h-[90%]">
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-3 gap-7 items-center w-full "
        >
          <div className="col-span-3 flex items-center gap-[100px] pt-64">
            <MapContainer
              center={[39.1417632, 34.1284977]}
              zoom={5}
              scrollWheelZoom={false}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {latLng.lat && (
                <Marker position={[latLng.lat, latLng.lng]} icon={ozelIcon}>
                  {/* Marker içerisinde ekstra içerik ekleyebilirsiniz */}
                  <Popup>
                    <span>Lat : {latLng?.lat}</span>
                    <br /> Lng: {latLng?.lng}
                  </Popup>
                </Marker>
              )}

              <MapComponent />
            </MapContainer>
          </div>

          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Cari Adı :
            </label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="salesPrice"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Kişi adı :
            </label>
            <input
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              type="text"
              id="salesPrice"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>

          <div>
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Sektör :
            </label>
            <Select
              value={sector}
              onChange={(e) => setSector(e)}
              options={sectorOptions}
              maxMenuHeight={200}
              classNamePrefix="custom-select"
              placeholder="Seçiniz..."
            />
          </div>
          <div>
            <label
              htmlFor="code"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Vergi Dairesi :
            </label>
            <input
              value={taxOffice}
              onChange={(e) => setTaxOffice(e.target.value)}
              type="text"
              id="code"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="ProductCode"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Vergi No :
            </label>
            <input
              value={taxNumber}
              onChange={(e) => setTaxNumber(e.target.value)}
              type="number"
              id="ProductCode"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Adres :
            </label>
            <input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Mail :
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Web Site :
            </label>
            <input
              value={webUrl}
              onChange={(e) => setWebUrl(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Posta Kodu :
            </label>
            <input
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>

          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Ülke :
            </label>
            <Select
              value={country}
              onChange={(e) => setCountry(e)}
              options={countryOptions}
              maxMenuHeight={200}
              classNamePrefix="custom-select"
              placeholder="Seçiniz..."
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              İl :
            </label>
            <Select
              value={city}
              onChange={(e) => districtChange(e)}
              options={cityOptions}
              maxMenuHeight={200}
              classNamePrefix="custom-select"
              placeholder="Seçiniz..."
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              İlçe :
            </label>
            <Select
              value={district}
              onChange={(e) => setDistrict(e)}
              options={districtOptions}
              maxMenuHeight={200}
              classNamePrefix="custom-select"
              placeholder="Seçiniz..."
            />
          </div>

          <div className="flex flex-col gap-[20px]">
            <div>
              <label
                className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="categories"
              >
                Durum :
              </label>
              <Select
                value={status}
                onChange={(e) => setStatus(e)}
                options={statusOptions}
                id="categories"
                name="colors"
                classNamePrefix="custom-select"
                maxMenuHeight={200}
                placeholder="Seçiniz..."
              />
            </div>
            <div>
              <label
                className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="categories"
              >
                Tip :
              </label>
              <Select
                value={type}
                onChange={(e) => setType(e)}
                options={typeOptions}
                id="categories"
                name="colors"
                classNamePrefix="custom-select"
                maxMenuHeight={200}
                placeholder="Seçiniz..."
              />
            </div>
          </div>

          <div className="flex flex-col gap-[20px]">
            <div>
              <label
                htmlFor="purchasePrice"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Tel No :
              </label>
              <input
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
                type="number"
                id="purchasePrice"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="purchasePrice"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Cep No :
              </label>
              <input
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
                type="number"
                id="purchasePrice"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>
          </div>
          <div className="col-span-1 ml-24">
            <input type="file" />
          </div>

          <div className="col-span-3">
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Anahtar Kelimeler :
            </label>
            <CreatableSelect
              isMulti
              options={customerKey}
              onChange={(e) => setKeywords(e)}
              maxMenuHeight={200}
              classNamePrefix="custom-select"
              placeholder="Seçiniz..."
            />
          </div>
          <div className="col-span-3">
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Açıklama :
            </label>
            <input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>

          <div className="col-span-3 flex justify-end gap-[20px]">
            <button
              onClick={onCancel}
              className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
            >
              Vazgeç
            </button>
            <button
              type="submit"
              className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
            >
              Kaydet
              <i className="fa-regular fa-floppy-disk ml-2"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAccount;
