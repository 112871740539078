import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";

const FormView = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setToken(localStorage.getItem("token"));

    api
      .get(`/api/FormBuilder/GetFormData?formID=${params.id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => setData(err.data))
      .finally(() => setLoading(false));
  }, [params.id]);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[{ label: "Formlar", href: "" }]}
            />
            <div className="m-12">
              <div className="add-button">
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title ">Formlar</h1>
                </div>
                <div className="flex">
                  <input
                    className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                    type="text"
                    placeholder="Form ara.."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
              <div className="relative overflow-x-auto h-[85vh]">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                    <tr>
                      {Object.keys(data[0])
                        .filter((key) => key !== "FormDataHeaderID") // FormDataHeaderID'yi filtrele
                        .map((key, index) => (
                          <th key={index} scope="col" className="px-6 py-3">
                            {key}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b hover:bg-gray-50"
                      >
                        {Object.keys(item)
                          .filter((key) => key !== "FormDataHeaderID") // FormDataHeaderID'yi filtrele
                          .map((key, index) => (
                            <td key={index} className="px-6 py-4">
                              {item[key]}
                            </td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default FormView;
