import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const StockCardBomAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [stockCards, setStockCards] = useState([]);
  const [allStockCards, setAllStockCards] = useState([]);
  const [receipeStockCards, setReceipeStockCards] = useState([]);
  const [bomTypes, setBomTypes] = useState([]);
  const [selectedBomType, setSelectedBomType] = useState({});
  const [selectedReceipeStockCards, setSelectedReceipeStockCards] = useState(
    {}
  );
  const [token, setToken] = useState(null);
  //FORM
  const [bomItems, setBomItems] = useState([]);
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [unitTypesOpt, setUnitTypesOpt] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=22`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataStockCards = res.data.items;
              setAllStockCards(dataStockCards);
              const newDataStockCards = dataStockCards?.map((item) => ({
                value: item.stockCardID,
                label: `${item.code} - ${item.name}`, // Kodu ve adı birleştir
              }));
              setStockCards(newDataStockCards);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/StockCardBOM/GetReceipeStockCards?customerID=${user.country}`
            )
            .then((res) => {
              const dataReceipeStockCards = res.data;
              const newDataReceipeStockCards = dataReceipeStockCards?.map(
                (item) => ({
                  value: item.stockCardID,
                  label: `${item.code} - ${item.name}`,
                })
              );
              setReceipeStockCards(newDataReceipeStockCards);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetParameters?parametername=UnitType")
            .then((res) => {
              const unitTypes = res.data;
              const newDataUnitTypes = unitTypes?.map((item) => ({
                value: item.parameterID,
                label: item.parameterValue2,
              }));
              setUnitTypesOpt(newDataUnitTypes);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=BOMType")
            .then((res) => {
              const bomTypes = res.data;
              const newDataBomTypes = bomTypes?.map((item) => ({
                value: item.parameterID,
                label: item.parameterValue2,
              }));
              setBomTypes(newDataBomTypes);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newDataKey = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setKeywordsData(newDataKey);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleServiceRemove = (index) => {
    const list = [...bomItems];
    list.splice(index, 1);
    setBomItems(list);
  };

  const handleServiceAdd = () => {
    setBomItems([
      ...bomItems,
      {
        stockCardID: Number(),
        unitType: Number(),
        quantity: Number(),
        wastageRate: Number(),
        description: "",
        showButton: false,
      },
    ]);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...bomItems];
    list[index][name] = value;
    setBomItems(list);
  };
  const handleSelectChange = (e, index) => {
    const list = [...bomItems];
    list[index].stockCardID = e;
    setBomItems(list);
  };
  const handleUnitTypeSelectChange = (e, index) => {
    const list = [...bomItems];
    list[index].unitType = e;
    setBomItems(list);
  };

  const postData = {
    customerID,
    stockCardID: selectedReceipeStockCards?.value,
    type: selectedBomType?.value,
    status: 0,
    viewIndex: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    stockCard_BOM_Items: bomItems?.map((item) => ({
      stockCardID: item.stockCardID.value,
      unitType: item.unitType,
      quantity: item.quantity,
      wastageRate: item.wastageRate,
      description: item.description,
    })),
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.post("/api/StockCardBOM/Post/Post", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Ürün reçetesi oluşturuldu !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/stockCardBOM");
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleProductSelect = (selectedProduct) => {
    setSelectedReceipeStockCards(selectedProduct);

    // stockCards'ta seçilen ürünün bilgilerini bul
    const selectedStockCard = allStockCards.find(
      (card) => card.stockCardID === selectedProduct.value
    );

    if (selectedStockCard) {
      // bomItems'a yeni öğe ekle
      setBomItems([
        {
          stockCardID: {value:selectedStockCard.stockCardID, label: `${selectedStockCard.code} - ${selectedStockCard.name}`},
          unitType: 0,
          quantity: 0,
          wastageRate: 0,
          description: "",
          showButton:true
        },
      ]);
    }
  };
  
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Ürün Reçeteleri", href: "/stockCardBOM" },
                { label: "Ürün Reçetesi Oluştur", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">
                  Ürün Reçetesi Oluştur
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-4 gap-7 items-center w-full"
              >
                <div className="col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Ürün Seç :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    options={receipeStockCards}
                    value={selectedReceipeStockCards}
                    onChange={handleProductSelect}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Reçete Tipi :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    options={bomTypes}
                    value={selectedBomType}
                    onChange={(e) => setSelectedBomType(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="col-span-4">
                  <label
                    htmlFor="keywords"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Anahtar Kelimeler :
                  </label>
                  <CreatableSelect
                    isMulti
                    maxMenuHeight={200}
                    id="keywords"
                    onChange={(e) => setKeywords(e)}
                    options={keywordsData}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>

                <div className="col-span-4">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <textarea
                    id="description"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className="overflow-x-auto mb-5 w-full overflow-y-auto col-span-4 mt-12 h-96">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Adet
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Fire Oranı (%)
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Açıklama
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İşlem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bomItems.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-6 py-4">
                            <div className="w-[200px]">
                              <Select
                                onChange={(e) =>
                                  handleSelectChange(e, index)
                                }
                                value={singleService.stockCardID}
                                options={stockCards}
                                maxMenuHeight={200}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..."
                              />
                            </div>
                          </td>

                          <td className="px-6 py-4">
                            <div className="w-[200px]">
                              <Select
                                onChange={(e) =>
                                  handleUnitTypeSelectChange(e.value, index)
                                }
                                options={unitTypesOpt}
                                maxMenuHeight={200}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..."
                              />
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.quantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="quantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.wastageRate}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="wastageRate"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.description}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="description"
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            />
                          </td>

                          <td className="px-6 py-4">
                         {singleService.showButton ? (
                          <p className="text-[#B10F0F] font-bold py-2 px-4 rounded-lg m-5">-</p>
                         ) : (
                             <button
                               onClick={() => handleServiceRemove(index)}
                               className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-2 px-4 rounded-lg m-5"
                             >
                               <i className="fa-regular fa-trash-can"></i>
                             </button>
                          
                         )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <button
                      onClick={handleServiceAdd}
                      type="button"
                      className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg my-5 h-10 w-24"
                    >
                      Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </table>
                </div>

                <div className="col-span-4 flex justify-end gap-[20px]">
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default StockCardBomAdd;
