import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const options = [
  { value: 1, label: "Tüm Makine ve Operasyonlar" },
  { value: 2, label: "Makine" },
  { value: 3, label: "Operasyon" },
];

const GeneralShiftAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [customerID, setCustomerID] = useState(Number());
  const [userID, setUserID] = useState(Number());
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [machines, setMachines] = useState([]);
  const [operations, setOperations] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState({});
  const [moID, setMoID] = useState({});

  //FORM
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=26`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          setCustomerID(user.country);
          setUserID(user.nameidentifier);
        })
        .catch((err) => console.log(err.data));

      api
        .get(
          `/api/Operation/Getoperations?customerID=${user.country}&pageNumber=1&pageSize=99999`
        )
        .then((res) => {
          const operationData = res.data.items;
          const newOperationData = operationData.map((item) => ({
            value: item.operationID,
            label: item.name,
          }));
          setOperations(newOperationData);
        })
        .catch((err) => {
          console.log(err.data);
        });

      api
        .get(
          `/api/Machine/GetMachines?customerID=${user.country}&pageNumber=1&pageSize=99999`
        )
        .then((res) => {
          const machineData = res.data.items;
          const newMachineData = machineData.map((item) => ({
            value: item.machineID,
            label: item.name,
          }));
          setMachines(newMachineData);
        })
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const startDateTime = new Date(startDate);
  startDateTime.setHours(0, 0, 0, 0); // Saati 00:00:00 yapar

  const endDateTime = new Date(endDate);
  endDateTime.setHours(23, 59, 59, 99); // Saati 23:59:59.999 yapar

  const postData = {
    customerID,
    startDate: startDateTime,
    endDate: endDateTime,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const isOperation = selectedProcess.value === 3; // Eğer "Operasyon" seçilmişse true, diğerleri için false

    if (selectedProcess.value === 1) {
      try {
        // Axios isteği yapılandırma
        await api.post("/api/Shift/PostShift/PostShift", postData);

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Genel Vardiya oluşturuldu !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/personelShifts");
      } catch (error) {
        console.error("Hata alındı: ", error);
        const errorMessageKey = error.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      } finally {
        setLoading(false);
      }
    } else {
      const postData2 = {
        customerID,
        startDate: startDateTime,
        endDate: endDateTime,
        moID: moID.value,
        isOperation, // Burada isOperation değerini kullanıyoruz
      };

      try {
        // Axios isteği yapılandırma
        await api.post(
          "/api/Shift/PostShiftByID/PostShiftByMachineOperationID",
          postData2
        );

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Genel Vardiya oluşturuldu !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/personelShifts");
      } catch (error) {
        console.error("Hata alındı: ", error);
        const errorMessageKey = error.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Vardiya Takvimi", href: "/personelShifts" },
                { label: "Genel Vardiya Oluştur", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">
                  Genel Vardiya Oluştur
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-2 gap-12 items-center w-full mt-5 pt-5"
              >
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    İşlem Seçiniz :
                  </label>

                  <Select
                    value={selectedProcess}
                    onChange={(e) => {
                      setSelectedProcess(e);
                      setMoID(null);
                    }}
                    options={options}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Makine / Operasyon :
                  </label>

                  <Select
                    value={moID}
                    onChange={(e) => setMoID(e)}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    options={
                      selectedProcess.value === 2 // Eğer Makine seçildiyse
                        ? machines
                        : selectedProcess.value === 3 // Eğer Operasyon seçildiyse
                        ? operations
                        : [] // Diğer durumlarda boş seçenek
                    }
                    isDisabled={selectedProcess.value === 1}
                    placeholder="Seçiniz..."
                  />
                </div>
                <div>
                  <label
                    htmlFor="startDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Başlangıç Tarih :
                  </label>
                  <DatePicker
                    id="startDate"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    locale={tr}
                    dateFormat="d MMMM, yyyy "
                  />
                </div>

                <div>
                  <label
                    htmlFor="endDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Bitiş Tarih :
                  </label>
                  <DatePicker
                    id="endDate"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    locale={tr}
                    dateFormat="d MMMM, yyyy"
                  />
                </div>

                <div className="col-span-2 flex justify-start gap-[20px]">
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default GeneralShiftAdd;
