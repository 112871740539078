import React, { memo } from "react";
import { Handle, Position } from "reactflow";

function CustomNodeEnd({ data, isConnectable }) {

  return (
    <>
    <div className="custom-action-default-end">
      <Handle
        id="b"
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div style={{ textAlign: "center"}}>
        <h1 style={{ fontWeight: "500", fontSize: "25px" }}>{data.label}</h1>
      </div>
   

    </div>
  </>
  );
}

export default memo(CustomNodeEnd);




















// export default memo(({ data, isConnectable }) => {

//   return (
//     <>
//     <div className="custom-action-default">
//       <Handle
//         id="b"
//         type="target"
//         position={Position.Left}
//         style={{ background: "#555" }}
//         onConnect={(params) => console.log("handle onConnect", params)}
//         isConnectable={isConnectable}
//       />
//       <div style={{ textAlign: "center"}}>
//         <h1 style={{ fontWeight: "500", fontSize: "25px" }}>{data.label}</h1>
//       </div>
   

//     </div>
//   </>
//   );
// });

// import React, { memo } from 'react';
// import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';

// const options = [
//   {
//     value: 'smoothstep',
//     label: 'Smoothstep',
//   },
//   {
//     value: 'step',
//     label: 'Step',
//   },
//   {
//     value: 'default',
//     label: 'Bezier (default)',
//   },
//   {
//     value: 'straight',
//     label: 'Straight',
//   },
// ];

// function Select({ value, handleId, nodeId }) {
//   const { setNodes } = useReactFlow();
//   const store = useStoreApi();

//   const onChange = (evt) => {
//     const { nodeInternals } = store.getState();
//     setNodes(
//       Array.from(nodeInternals.values()).map((node) => {
//         if (node.id === nodeId) {
//           node.data = {
//             ...node.data,
//             selects: {
//               ...node.data.selects,
//               [handleId]: evt.target.value,
//             },
//           };
//         }

//         return node;
//       })
//     );
//   };

//   return (
//     <div className="custom-node__select">
//       <div>Edge Type</div>
//       <select className="nodrag" onChange={onChange} value={value}>
//         {options.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//       <Handle position={Position.Right} id={handleId} />
//     </div>
//   );
// }

// function CustomNode({ id, data }) {
//   return (
//     <>
//       <div className="custom-node__header">
//         This is a <strong>custom node</strong>
//       </div>
//       <div className="custom-node__body">
//         {Object.keys(data.selects).map((handleId) => (
//           <Select key={handleId} nodeId={id} value={data.selects[handleId]} handleId={handleId} />
//         ))}
//       </div>
//     </>
//   );
// }

// export default memo(CustomNode);
