import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";

const PersonelWages = () => {
  const { user } = useAuth();
  const params = useParams();
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=25`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Personel/GetPersonWages?customerID=${user.country}&personID=${params.id}`
            )
            .then((res) => {
              setData(res.data);
            })
            .catch((err) => console.error(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleDelete = async (wageID) => {
    try {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      Swal.fire({
        title: "Personel bilgilerini silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor:"#0C692F",
        cancelButtonColor:"#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Personel/DeletePersonWage/${wageID}?customerID=${customerID}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.wageID !== wageID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Maaş silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting project:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Maaş silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Personeller", href: "/personels" },
                { label: "Personel Maaşları", href: "" },
              ]}
            />
            <div className="m-12 min-h-screen">
              <div className="add-button">
                {permissions.insertPermission && (
                  <Link to={`/personelWageAdd/${params.id}`}>
                    <button
                      type="button"
                       className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-72"
                    >
                      Maaş Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </Link>
                )}

                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">Maaşlar</h1>
                </div>
                <div className="flex">
                  <input
                    className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                    type="text"
                    placeholder="Maaş ara.."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>

              <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Maaş Miktarı
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Maaş Giriş Tarihi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İşlemler
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data
                        ?.filter(
                          (item) =>
                            item.amount
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.wageStartDate
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase())
                        )
                        .map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b hover:bg-gray-50"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {item?.amount}
                            </th>
                            <td className="px-6 py-4">
                              {formatDate(item.wageStartDate)}
                            </td>
                            <td className="flex items-center ml-9  py-4 space-x-3">
                              {/* <Link to={`/personelWageEdit/${item.wageID}`}>
                             <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg m-5">
                               Maaş Düzenle
                             </button>
                           </Link> */}
                              {permissions.deletePermission && (
                                <button
                                  onClick={() => {
                                    handleDelete(item.wageID);
                                  }}
                                >
                                  <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                    <RiDeleteBin6Line className="hover:scale-110 transition-transform duration-200" size={24} />
                                  </a>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td
                          colSpan={3}
                          className="px-6 py-4 text-center text-2xl font-medium"
                        >
                          Henüz kayıtlı bir veri yoktur.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PersonelWages;
