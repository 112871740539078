import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import Select from "react-select";
import InputMask from "react-input-mask";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const PersonelEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [title, setTitle] = useState("");
  const [identityNumber, setIdentityNumber] = useState("");
  const [socialNumber, setSocialNumber] = useState("");
  const [birthDate, setBirthDate] = useState(new Date());
  const [startDateOfWork, setStartDateOfWork] = useState(new Date());
  const [gender, setGender] = useState("");
  const [militaryStatus, setMilitaryStatus] = useState(false);
  const [telephone, setTelephone] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [data, setData] = useState([]);
  const [bloodType, setBloodType] = useState([]);
  const [selectedBloodType, setSelectedBloodType] = useState([]);
  const [description, setDescription] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [birthPlace, setBirthPlace] = useState("");

  const [getCity, setGetCity] = useState([]);
  const [getDistrict, setGetDistrict] = useState([]);
  const [city, setCity] = useState({});
  const [district, setDistrict] = useState({});
  const [operations, setOperations] = useState([]);
  const [operationsData, setOperationsData] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState([]);
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState([]);
  const [machinesData, setMachinesData] = useState([]);
  const [personelGroups, setPersonelGroups] = useState([]);
  const [personelGroupData, setPersonelGroupData] = useState([]);
  const [selectedPersonelGroups, setSelectedPersonelGroups] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=25`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Operation/Getoperations?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setOperationsData(res.data.items);
              const operationData = res.data.items;
              const newOperationData = operationData.map((item) => ({
                value: item.operationID,
                label: item.title,
              }));
              setOperations(newOperationData);
            })
            .catch((err) => {
              console.log(err.data);
            });
          api
            .get(
              `/api/Machine/GetMachines?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setMachinesData(res.data.items);
              const machineData = res.data.items;
              const newMachineData = machineData.map((item) => ({
                value: item.machineID,
                label: item.title,
              }));
              setMachines(newMachineData);
              console.log(machineData);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=BloodGroup")
            .then((res) => {
              const bloodData = res.data;
              const newBloodData = bloodData.map((item) => ({
                value: item.parameterID, //backend istenen
                label: item.parameterValue2, //ekrana basılan
              }));
              setBloodType(newBloodData);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetParameters?parametername=City")
            .then((res) => {
              setGetCity(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=District")
            .then((res) => {
              setGetDistrict(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Personel/GetPersonGroups?customerID=${user.country}`)
            .then((res) => {
              setPersonelGroupData(res.data);
              const personelGroupData = res.data;
              const newPersonelGroupData = personelGroupData.map((item) => ({
                value: item.personGroupID, //backend istenen
                label: item.name, //ekrana basılan
              }));
              setPersonelGroups(newPersonelGroupData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Department/GetDepartments?customerID=${user.country}`)
            .then((res) => {
              const departmentData = res.data;
              const newDepartmentData = departmentData.map((item) => ({
                value: item.departmentID,
                label: item.title,
              }));
              setDepartments(newDepartmentData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Personel/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setData(res.data);
              setAddress(res.data.address);
              setName(res.data.name);
              setSurname(res.data.surname);
              setIdentityNumber(res.data.identityNumber);
              setTitle(res.data.title);
              setSocialNumber(res.data.socialNumber);
              setBirthPlace(res.data.birthPlace);
              setTelephone(res.data.telephone);
              setCellphone(res.data.cellphone);
              setEmail(res.data.email);
              setDescription(res.data.description);
              setMilitaryStatus(res.data.militaryStatus);
              setBirthDate(new Date(res.data.birthDate));
              if (res.data.startDateOfWork) {
                setStartDateOfWork(new Date(res.data.startDateOfWork));
              } else {
                setStartDateOfWork(null);
              }

              setSelectedDepartment({
                label: res.data.departmentName,
                value: res.data.departmentID,
              });
              setSelectedBloodType({
                label: res.data.bloodGroupName,
                value: res.data.bloodGroupID,
              });
              setDistrict({
                label: res.data.district,
                value: res.data.districtID,
              });
              setCity({ label: res.data.city, value: res.data.cityID });
              setGender(res.data.gender);
              setSelectedMachine({
                label: res.data.name,
                value: res.data.machineID,
              });
              setSelectedOperation({
                label: res.data.name,
                value: res.data.operationID,
              });
              setSelectedPersonelGroups({
                label: res.data.name,
                value: res.data.personGroupID,
              });
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const genderOptions = [
    { value: "E", label: "Erkek" },
    { value: "K", label: "Kadın" },
  ];

  const handleGenderChange = (selectedOption) => {
    setGender(selectedOption.value);
  };

  const handleMilitaryStatusChange = () => {
    setMilitaryStatus(!militaryStatus);
  };

  const districtChange = (e) => {
    setCity(e);
    api
      .get(`/api/Common/GetDistricts?city=${e.parameterValue1}`)
      .then((res) => {
        setGetDistrict(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const cityOptions = getCity?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
    parameterValue1: item.parameterValue1,
  }));

  const districtOptions = getDistrict?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  useEffect(() => {
    if (machinesData && data && operationsData && personelGroupData) {
      const matchedID = machinesData.find(
        (item) => item.machineID === data.machineID
      );

      const matcedOperationID = operationsData.find(
        (item) => item.operationID === data.operationID
      );
      const matcedPersonGroupID = personelGroupData.find(
        (item) => item.personGroupID === data.personelGroupID
      );
      setSelectedOperation({
        value: matcedOperationID?.operationID,
        label: matcedOperationID?.name,
      });
      setSelectedPersonelGroups({
        value: matcedPersonGroupID?.personGroupID,
        label: matcedPersonGroupID?.name,
      });

      setSelectedMachine({
        value: matchedID?.machineID,
        label: matchedID?.name,
      });
    }
  }, [data, machinesData, operationsData, personelGroupData]);

  const postData = {
    personelID: params.id,
    departmentID: selectedDepartment.value,
    machineID: selectedMachine?.value ? selectedMachine.value : null,
    operationID: selectedOperation.value ? selectedOperation.value : null,
    personGroupID: selectedPersonelGroups.value
      ? selectedPersonelGroups.value
      : null,
    title,
    name,
    surname,
    identityNumber,
    startDateOfWork,
    birthDate,
    birthPlace,
    gender,
    militaryStatus,
    bloodGroupID: selectedBloodType.value,
    telephone,
    cellphone,
    address,
    districtID: district.value,
    cityID: city.value,
    email,
    description,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.put("/api/Personel/Put/Put", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Personel güncellendi !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/personels");
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Personeller", href: "/personels" },
                { label: "Personel Güncelle", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">
                  Personel Güncelle
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-2 gap-7 items-center w-full"
              >
                <div className="">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Başlık :
                  </label>
                  <input
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Sicil No :
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    disabled
                    value={data?.registerNumber}
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="departments"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Departman :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    id="departmentSelect"
                    options={departments}
                    value={selectedDepartment}
                    onChange={(e) => setSelectedDepartment(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Adı :
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="surname"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Soyadı :
                  </label>
                  <input
                    type="text"
                    id="surname"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="identityNumber"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    TC :
                  </label>
                  <input
                    type="text"
                    id="identityNumber"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={identityNumber}
                    onChange={(e) => setIdentityNumber(e.target.value)}
                  />
                </div>

                <div className="col-span-1 flex justify-between">
                  <div>
                    <label
                      htmlFor="birthDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Doğum Tarihi :
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={birthDate}
                      onChange={(date) => setBirthDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="startDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      İşe Giriş Tarih :
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                      selected={startDateOfWork}
                      onChange={(date) => setStartDateOfWork(date)}
                      required
                    />
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor="birthPlace"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Doğum Yeri :
                  </label>
                  <input
                    type="text"
                    id="birthPlace"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={birthPlace}
                    onChange={(e) => setBirthPlace(e.target.value)}
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="personelGroups"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Personel Grubu :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    id="personelGroupsSelect"
                    options={personelGroups}
                    value={selectedPersonelGroups}
                    onChange={(e) => setSelectedPersonelGroups(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="departments"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Operasyon :
                  </label>

                  <Select
                    maxMenuHeight={200}
                    id="operationSelect"
                    options={operations}
                    value={selectedOperation}
                    onChange={(e) => setSelectedOperation(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="departments"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Makine :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    id="machineSelect"
                    options={machines}
                    value={selectedMachine}
                    onChange={(e) => setSelectedMachine(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="gender"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Cinsiyet :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    id="gender"
                    options={genderOptions}
                    value={genderOptions.find(
                      (option) => option.value === gender
                    )}
                    onChange={handleGenderChange}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="militaryStatus"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Askerlik Durumu :
                  </label>
                  <input
                    type="checkbox"
                    checked={militaryStatus}
                    onChange={handleMilitaryStatusChange}
                  />
                  <span className="mx-2">Yapıldı</span>
                </div>

                <div className="">
                  <label
                    htmlFor="bloodType"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kan Grubu :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    id="bloodType"
                    options={bloodType}
                    value={selectedBloodType}
                    onChange={(e) => setSelectedBloodType(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="telephone"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Telefon :
                  </label>
                  <InputMask
                    id="phoneNumber"
                    type="tel"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Telefon"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                    mask="(999) 999-9999" // Telefon numarası formatı burada belirtiliyor
                    required
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Email :
                  </label>
                  <input
                    type="text"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="city"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    İl :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    id="city"
                    options={cityOptions}
                    value={city}
                    onChange={(e) => districtChange(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="district"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    İlçe :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    id="district"
                    options={districtOptions}
                    value={district}
                    onChange={(e) => setDistrict(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="cellphone"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    GSM :
                  </label>
                  <InputMask
                    id="cellphone"
                    type="tel"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="GSM"
                    value={cellphone}
                    onChange={(e) => setCellphone(e.target.value)}
                    mask="(999) 999-9999" // Telefon numarası formatı burada belirtiliyor
                  />
                </div>
                <div>
                  <label
                    htmlFor="address"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Adres :
                  </label>
                  <textarea
                    id="address"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    rows={4}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <textarea
                    id="description"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className="col-span-2 flex justify-end gap-[20px]">
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PersonelEdit;
