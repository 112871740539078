import React, { memo, useState } from "react";
import { Handle, Position } from "reactflow";

function CustomNode({ data, isConnectable }) {
  const [selectedOption, setSelectedOption] = useState("");

  // Seçeneklerin bulunduğu dizi
  const options = ["Oluşturma", "Güncelleme", "Silme"];

  // Select değiştiğinde çağrılacak fonksiyon
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div style={{ textAlign: "center", margin: "10px 0" }}>
        <h1 style={{ fontWeight: "500", fontSize: "12px" }}>{data.label}</h1>
      </div>
      <select
        id="selectOptions"
        onChange={handleSelectChange}
        value={selectedOption}
        className="w-full text-center mb-6"
        style={{ fontSize: "15px" }}
      >
        <option value="">Seçiniz</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={{ top: 10, background: "#555" }}
        isConnectable={isConnectable}
      />
    </>
  );
}

export default memo(CustomNode);

// export default memo(({ data, isConnectable }) => {
//   const [selectedOption, setSelectedOption] = useState('');

//   // Seçeneklerin bulunduğu dizi
//   const options = ['Oluşturma', 'Güncelleme', 'Silme'];

//   // Select değiştiğinde çağrılacak fonksiyon
//   const handleSelectChange = (event) => {
//     const value = event.target.value;
//     setSelectedOption(value);
//   };
//   return (
//     <>
//       <Handle
//         type="target"
//         position={Position.Left}
//         style={{ background: '#555' }}
//         onConnect={(params) => console.log('handle onConnect', params)}
//         isConnectable={isConnectable}
//       />
//       <div style={{textAlign:"center", margin:"10px 0"}}>
//         <h1 style={{fontWeight:"500", fontSize:"12px"}}>{data.label}</h1>
//       </div>
//       <select
//         id="selectOptions"
//         onChange={handleSelectChange}
//         value={selectedOption}
//         className='w-full text-center mb-6'
//         style={{fontSize:"15px"}}
//       >
//         <option value="">Seçiniz</option>
//         {options.map((option, index) => (
//           <option key={index} value={option}>
//             {option}
//           </option>
//         ))}
//       </select>
//       <Handle
//         type="source"
//         position={Position.Right}
//         id="a"
//         style={{ top: 10, background: '#555' }}
//         isConnectable={isConnectable}
//       />

//     </>
//   );
// });

// import React, { memo } from 'react';
// import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';

// const options = [
//   {
//     value: 'smoothstep',
//     label: 'Smoothstep',
//   },
//   {
//     value: 'step',
//     label: 'Step',
//   },
//   {
//     value: 'default',
//     label: 'Bezier (default)',
//   },
//   {
//     value: 'straight',
//     label: 'Straight',
//   },
// ];

// function Select({ value, handleId, nodeId }) {
//   const { setNodes } = useReactFlow();
//   const store = useStoreApi();

//   const onChange = (evt) => {
//     const { nodeInternals } = store.getState();
//     setNodes(
//       Array.from(nodeInternals.values()).map((node) => {
//         if (node.id === nodeId) {
//           node.data = {
//             ...node.data,
//             selects: {
//               ...node.data.selects,
//               [handleId]: evt.target.value,
//             },
//           };
//         }

//         return node;
//       })
//     );
//   };

//   return (
//     <div className="custom-node__select">
//       <div>Edge Type</div>
//       <select className="nodrag" onChange={onChange} value={value}>
//         {options.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//       <Handle position={Position.Right} id={handleId} />
//     </div>
//   );
// }
