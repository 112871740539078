import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const RoleIdentify = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [customerID, setCustomerID] = useState([]);
  const [data, setData] = useState([]);
  const [role, setRole] = useState([]);
  const [assignedModules, setAssignedModules] = useState([]);
  const [preAssignedModules, setPreAssignedModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showChecks, setShowChecks] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [token, setToken] = useState(null);

  // CHECK STATES
  const [insertPermission, setInsertPermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [reportPermission, setReportPermission] = useState(false);
  const [showPricePermission, setShowPricePermission] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=10`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/User/GetAllModules`)
            .then((res) => setData(res.data))
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/User/GetRole?customerID=${user.country}&roleID=${params.id}`
            )
            .then((res) => {
              setRole(res.data);
              const a1 = res.data.rolesModules;
              const a2 = a1.map((item) => item.module);
              setPreAssignedModules(a2);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);
  console.log(data);

  const handleItemClick = (item) => {
    setSelectedItemData(item);
    setShowChecks(true);
  };

  const moveItemToAssigned = () => {
    if (selectedItemData && !assignedModules.includes(selectedItemData)) {
      const yeniOge = {
        ...selectedItemData,
        customerID,
        roleID: params.id,
        insertPermission,
        updatePermission,
        deletePermission,
        reportPermission,
        showPricePermission,
      };

      setAssignedModules((oncekiModuller) => [...oncekiModuller, yeniOge]);
      setData((oncekiData) =>
        oncekiData.filter((oge) => oge !== selectedItemData)
      );
      setSelectedItemData(null);
      setShowChecks(false);
    }
  };

  const handleSave = () => {
    const assignedModulesWithPermissions = assignedModules?.map((item) => ({
      moduleID: item.moduleID,
      roleID: params.id,
      customerID,
      insertPermission,
      updatePermission,
      deletePermission,
      reportPermission,
      showPricePermission,
      description: "",
    }));

    api
      .post(
        "/api/User/PostRolesModules/PostRolesModules",
        assignedModulesWithPermissions
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "İşlem başarılı !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/roles");
      })
      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey 
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey }) 
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" }); 
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const moveItemToAllModules = () => {
    if (selectedItemData && !data.includes(selectedItemData)) {
      setData((prevData) => [...prevData, selectedItemData]);
      setAssignedModules((prevModules) =>
        prevModules.filter((item) => item !== selectedItemData)
      );
      setSelectedItemData(null);
    }
    setShowChecks(false);
  };
  const handleDelete = async (moduleID) => {
    const dataToDelete = {
      customerID: customerID,
      roleID: params.id,
      moduleID,
    };

    try {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim!",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete("/api/User/DeleteRoleModule/DeleteRoleModule", {
              data: dataToDelete,
            })
            .then(() => {
              // Modülü preAssignedModules listesinden çıkar
              setPreAssignedModules((prevModules) =>
                prevModules.filter((item) => item.moduleID !== moduleID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Modül silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.log(error);
              Swal.fire("İşlem başarısız!");
            });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(data);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[75%] bg-gray-100 flex flex-col gap-20">
            <div className="flex justify-between items-center w-full ">
              <h1 className="text-3xl font-semibold text-gray-500">
                Rol: <span className="font-medium ml-2 title">{role.name}</span>
              </h1>
              <div>
                <button
                  onClick={handleSave}
                  type="submit"
                  className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center h-12"
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </div>

            <div className="flex gap-16 h-full w-full justify-between">
              <div className="overflow-y-scroll max-h-[500px] min-w-[350px] origin-top-right right-0 mt-2 w-64 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <h1 className="px-4 py-1 text-lg font-medium text-white bg-[#050A27] sticky top-0">
                  Tüm Modüller
                </h1>
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {data
                    .filter(
                      (item) =>
                        !preAssignedModules.some(
                          (assignedItem) =>
                            assignedItem.moduleID === item.moduleID
                        ) &&
                        !assignedModules.some(
                          (assignedItem) =>
                            assignedItem.moduleID === item.moduleID
                        )
                    )
                    .map((item, index) => (
                      <button
                        key={index}
                        className={`block px-4 py-2 text-sm font-medium hover:bg-gray-100 focus:outline-none focus:bg-gray-100 
                  ${
                    item.parent === 0
                      ? "text-red-500 hover:text-red-700 focus:text-red-700 font-semibold"
                      : "text-gray-700 hover:text-gray-900 focus:text-gray-900"
                  }`}
                        role="menuitem"
                        onClick={() => handleItemClick(item)}
                        type="button"
                      >
                        {item.name}
                      </button>
                    ))}
                </div>
              </div>
              <div className="flex flex-col gap-7 pt-52">
                <button
                  type="button"
                  className="w-14 hover:scale-110 transition-transform duration-200"
                  onClick={moveItemToAssigned}
                >
                  <FaArrowRightLong color="green" size={50} />
                </button>
                <button
                  type="button"
                  className="w-14 hover:scale-110 transition-transform duration-200"
                  onClick={moveItemToAllModules}
                >
                  <FaArrowLeftLong color="#E4003A" size={50} />
                </button>
              </div>
              <div className="overflow-y-scroll max-h-[500px] min-w-[350px] origin-top-right right-0 mt-2 w-64 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <h1 className="px-4 py-1 text-lg font-medium text-white bg-[#050A27] sticky top-0">
                  Atanacak Modüller
                </h1>
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {assignedModules.map((item) => (
                    <button
                      key={item.id}
                      className={`block px-4 py-2 text-sm font-medium hover:bg-gray-100 focus:outline-none focus:bg-gray-100 
                    ${
                      item.parent === 0
                        ? "text-red-500 hover:text-red-700 focus:text-red-700 font-semibold"
                        : "text-gray-700 hover:text-gray-900 focus:text-gray-900"
                    }`}
                      role="menuitem"
                      onClick={() => handleItemClick(item)}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
              <div className="overflow-y-scroll max-h-[500px] min-w-[350px] origin-top-right right-0 mt-2 w-64 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <h1 className="px-4 py-1 text-lg font-medium text-white bg-[#050A27] sticky top-0">
                  Atanmış Modüller
                </h1>
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {preAssignedModules.map((item, index) => (
                    <button
                      key={index}
                      className="text-left block w-full px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                      role="menuitem"
                    >
                      <div className="flex justify-between items-center">
                        <p>{item.name}</p>
                        <button
                          onClick={() => {
                            handleDelete(item.moduleID);
                          }}
                          className="font-medium text-red-600 hover:underline"
                        >
                          <RiDeleteBin6Line />
                        </button>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {showChecks && (
              <ul className="items-center text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex justify-center w-full mb-28">
                <li className="w-full">
                  <div className="flex items-center ps-3">
                    <input
                      id="laravel-checkbox-list"
                      type="checkbox"
                      value={reportPermission}
                      className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={() => setReportPermission(!reportPermission)}
                    />
                    <label
                      htmlFor="laravel-checkbox-list"
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                    >
                      Raporlar
                    </label>
                  </div>
                </li>
                <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
                  <div className="flex items-center ps-3">
                    <input
                      id="vue-checkbox-list"
                      type="checkbox"
                      value={insertPermission}
                      onChange={() => setInsertPermission(!insertPermission)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    />
                    <label
                      htmlFor="vue-checkbox-list"
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                    >
                      Ekleme
                    </label>
                  </div>
                </li>
                <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
                  <div class="flex items-center ps-3">
                    <input
                      id="react-checkbox-list"
                      type="checkbox"
                      value={updatePermission}
                      onChange={() => setUpdatePermission(!updatePermission)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    />
                    <label
                      htmlFor="react-checkbox-list"
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                    >
                      Güncelleme
                    </label>
                  </div>
                </li>
                <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
                  <div class="flex items-center ps-3">
                    <input
                      id="showPrice"
                      type="checkbox"
                      value={showPricePermission}
                      onChange={() =>
                        setShowPricePermission(!showPricePermission)
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    />
                    <label
                      htmlFor="showPrice"
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                    >
                      Fiyat Görünümü
                    </label>
                  </div>
                </li>
                <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
                  <div className="flex items-center ps-3">
                    <input
                      id="angular-checkbox-list"
                      type="checkbox"
                      value={deletePermission}
                      onChange={() => setDeletePermission(!deletePermission)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    />
                    <label
                      htmlFor="angular-checkbox-list"
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                    >
                      Silme
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default RoleIdentify;
