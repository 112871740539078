import React from "react";
import logo from "../../assets/images/logo.png";

const Footer = () => {
  return (
    <footer className="w-full bg-gray-50">
      {/* <div className="px-4 sm:px-6 lg:px-8">
        <div className="py-5">
          <div className="flex items-center justify-center flex-col lg:space-y-0 space-y-8 lg:justify-between lg:flex-row">
            <a href="#" className="flex justify-center ">
              <img className="w-40" src={logo} alt="" />
            </a>
            <span className="text-gray-400  block">
              © Telif Hakkı 2024 | Tüm Hakları Saklıdır.
            </span>
            <div className="flex mt-4 space-x-4 sm:justify-center sm:mt-0 ">
              <a
                href='mailto:info@gamino.tech'
                type="button"
                className="w-9 h-9 rounded-full border border-gray-300 flex justify-center items-center hover:border-gray-600"
                data-twe-ripple-init
                data-twe-ripple-color="light"
              >
                <span class="mx-auto [&>svg]:h-4 [&>svg]:w-5">
                  <svg
                    className="w-[2rem] h-[2rem] text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                  </svg>
                </span>
              </a>
              <a
                href="https://propexerp.com/"
                type="button"
                className="w-9 h-9 rounded-full border border-gray-300 flex justify-center items-center hover:border-gray-600"
                data-twe-ripple-init
                data-twe-ripple-color="light"
              >
                <span class="mx-auto [&>svg]:h-4 [&>svg]:w-5">
                  <svg
                    className="w-[1.25rem] h-[1.125rem] text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 488 512"
                  >
                    <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                  </svg>
                </span>
              </a>
              <a
                href="https://www.linkedin.com/company/gamino-teknoloji/"
                className="w-9 h-9 rounded-full border border-gray-300 flex justify-center items-center hover:border-gray-600"
              >
                <svg
                  className="w-[1.25rem] h-[1.125rem] text-gray-500"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.8794 11.5527V3.86835H0.318893V11.5527H2.87967H2.8794ZM1.59968 2.81936C2.4924 2.81936 3.04817 2.2293 3.04817 1.49188C3.03146 0.737661 2.4924 0.164062 1.61666 0.164062C0.74032 0.164062 0.167969 0.737661 0.167969 1.49181C0.167969 2.22923 0.723543 2.8193 1.5829 2.8193H1.59948L1.59968 2.81936ZM4.29668 11.5527H6.85698V7.26187C6.85698 7.03251 6.87369 6.80255 6.94134 6.63873C7.12635 6.17968 7.54764 5.70449 8.25514 5.70449C9.18141 5.70449 9.55217 6.4091 9.55217 7.44222V11.5527H12.1124V7.14672C12.1124 4.78652 10.8494 3.68819 9.16483 3.68819C7.78372 3.68819 7.17715 4.45822 6.84014 4.98267H6.85718V3.86862H4.29681C4.33023 4.5895 4.29661 11.553 4.29661 11.553L4.29668 11.5527Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
