import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { Link, useNavigate } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from "sweetalert2";
import { FaUser } from "react-icons/fa";
import Switch from "react-switch";
import { FaQuestion } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const TasksByCreatorUser = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [projects, setProjects] = useState([]);
  const [matchedData, setMatchedData] = useState([]);
  const [matchedAllData, setMatchedAllData] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=13`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Task/GetTasksByCreatorUser?customerID=${user.country}&id=${user.nameidentifier}`
            )
            .then((res) => setData(res.data))
            .catch((err) => console.log(err.data));
          api
            .get(
              `/api/Task/GetAllTasksByCreatorUser?customerID=${user.country}&id=${user.nameidentifier}`
            )
            .then((res) => setAllData(res.data))
            .catch((err) => console.log(err.data));
          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => setUsers(res.data))
            .catch((err) => console.log(err.data));
          api
            .get(
              `/api/Project/GetProjects?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => setProjects(res.data.items))
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  const handleDelete = async (taskID, customerID) => {
    try {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/api/Task/DeleteTask/${taskID}?customerID=${customerID}`)
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.taskID !== taskID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Görev silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Görev silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  const handleAskStatus = async (taskID) => {
    Swal.fire({
      title: "Durum raporu istemek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0C692F",
      cancelButtonColor: "#B10F0F",
      confirmButtonText: "Evet, iste!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          await api.put(`/api/Task/PutTaskAskStatus?taskID=${taskID}`);

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Durum Raporu İstendi!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (error) {
          const errorMessageKey = error.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${translatedError}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getEndDateStatus = (endDate, status) => {
    const today = new Date();
    const endTaskDate = new Date(endDate);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1); // Yarın

    if (status === 1522) {
      return <p className="text-green-600 font-medium">Tamamlandı</p>;
    } else if (status === 72) {
      return <p className="text-red-600 font-medium">İptal Edildi</p>;
    } else if (status === 73) {
      return <p className="text-yellow-600 font-medium">Onay Bekliyor</p>;
    } else if (endTaskDate.toDateString() === tomorrow.toDateString()) {
      return <p className="text-yellow-500 font-medium">Süre Dolmak Üzere</p>;
    } else if (endTaskDate > today) {
      return <p className="text-blue-600 font-medium">Devam Ediyor</p>;
    } else {
      return <p className="text-red-600 font-medium">Süre Doldu</p>;
    }
  };

  const handleNavigate = (id) => {
    navigate(`/taskDetailByAssignedUser/${id}`);
  };

  const handleStatusChange = async (id) => {
    Swal.fire({
      title: "Görevi onaylamak istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0C692F",
      cancelButtonColor: "#B10F0F",
      confirmButtonText: "Evet, onayla!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const storedToken =
            localStorage.getItem("token") || sessionStorage.getItem("token");

          if (!storedToken) {
            throw new Error("Token bulunamadı."); // Token yoksa hata fırlat
          }

          await api.put(
            `/api/Task/PutTaskStatus?taskID=${id}&status=1522`,
            null,
            {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            }
          );

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Görev onaylandı!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (error) {
          const errorMessageKey = error.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${translatedError}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          setLoading(false);
          window.location.reload();
        }
      }
    });
  };

  useEffect(() => {
    if (data && projects && allData) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedUsers = data.map((item) => {
        const matchedProject = projects.find(
          (project) => project.projectID === item.projectID
        );
        if (matchedProject) {
          return {
            ...item,
            projectName: matchedProject.name,
          };
        }
        return item;
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setMatchedData(matchedUsers);

      ////////////////////// ALL DATA //////////////////////
      const matchedAllUsers = allData.map((item) => {
        const matchedProject = projects.find(
          (project) => project.projectID === item.projectID
        );
        if (matchedProject) {
          return {
            ...item,
            projectName: matchedProject.name,
          };
        }
        return item;
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setMatchedAllData(matchedAllUsers);
    }
  }, [data, projects, allData]);

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="mt-7 min-h-screen w-full">
          <div className="add-button">
            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title ">
                Atadığım Görevler
              </h1>
            </div>
            {permissions.insertPermission && (
              <Link to={"/taskAdd"}>
                <button
                  type="button"
                  className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-72"
                >
                  Görev Oluştur
                  <i className="fa-solid fa-plus ml-2"></i>
                </button>
              </Link>
            )}
            <div className="flex flex-row justify-between ">
              <input
                className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                type="text"
                placeholder="Görev ara.."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />

              <div className="flex gap-4 mr-8 my-2 items-center">
                <label
                  className="block text-base font-medium text-gray-600"
                  htmlFor="isChecked"
                >
                  Biten ve iptal edilen görevleri göster
                </label>
                <Switch
                  id="isChecked"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e)}
                />
              </div>
            </div>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Görev Başlığı
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Görev Adı
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Başlangıç Tarih
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Bitiş Tarih
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Proje
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Atanan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Durum
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tamamlanma Durumu
                  </th>
                  <th scope="col" className="px-6 py-3">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {isChecked
                  ? matchedAllData
                      ?.filter((item) =>
                        item.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => {
                        const rowClass =
                          item.status === 73 ? "bg-yellow-50" : "bg-white";
                        const rowText =
                          item.status === 1522 || item.status === 72
                            ? "line-through"
                            : "";

                        return (
                          <tr
                            key={index}
                            className={`border-b hover:bg-gray-50 ${rowClass} cursor-pointer`}
                          >
                            <th
                              scope="row"
                              className={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap ${rowText}`}
                              onClick={() => handleNavigate(item.taskID)}
                              title={item.name}
                            >
                              {truncateText(item.name, 30)}
                            </th>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className={`px-6 py-4 ${rowText}`}
                              title={item.title}
                            >
                              {truncateText(item.title, 30)}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4"
                            >
                              {formatDate(item.startDate)}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4"
                            >
                              {formatDate(item.endDate)}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4"
                            >
                              {item.projectName}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4 flex gap-3"
                            >
                              <FaUser color="gray" size={15} />
                              {item?.tasks_AssignedUsers[0]?.user?.userName}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4"
                            >
                              {getEndDateStatus(item.endDate, item.status)}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4"
                            >
                              <div className="w-full bg-gray-200 rounded-lg h-2.5 ">
                                <div
                                  className="bg-slate-600 h-2.5 rounded-lg"
                                  style={{ width: `${item.intensityPercent}%` }}
                                ></div>
                              </div>
                            </td>
                            <td className="flex items-center px-6 py-4 space-x-3">
                              {permissions.updatePermission && (
                                <Link
                                  to={`/tasksByCreatorUserEdit/${item.taskID}`}
                                >
                                  <a className="font-medium text-[#050A27] hover:underline">
                                    <TbEdit
                                      className="hover:scale-110 transition-transform duration-200"
                                      size={24}
                                    />
                                  </a>
                                </Link>
                              )}

                              {permissions.deletePermission && (
                                <button
                                  onClick={() => {
                                    handleDelete(item.taskID, item.customerID);
                                  }}
                                >
                                  <a className="font-medium text-[#050A27] hover:underline">
                                    <RiDeleteBin6Line
                                      className="hover:scale-110 transition-transform duration-200"
                                      size={24}
                                    />
                                  </a>
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                  : matchedData
                      ?.filter((item) =>
                        item.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => {
                        const rowClass =
                          item.status === 73 ? "bg-yellow-50" : "bg-white";
                        const rowText =
                          item.status === 1522 ? "line-through" : "";

                        return (
                          <tr
                            key={index}
                            className={`border-b hover:bg-gray-50 ${rowClass} cursor-pointer`}
                          >
                            <th
                              scope="row"
                              className={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap ${rowText}`}
                              onClick={() => handleNavigate(item.taskID)}
                              title={item.name}
                            >
                              {truncateText(item.name, 30)}
                            </th>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className={`px-6 py-4 ${rowText}`}
                              title={item.title}
                            >
                              {truncateText(item.title, 30)}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4"
                            >
                              {formatDate(item.startDate)}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4"
                            >
                              {formatDate(item.endDate)}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4"
                            >
                              {item.projectName}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4 flex gap-3"
                            >
                              <FaUser color="gray" size={15} />
                              {item?.tasks_AssignedUsers[0]?.user?.userName}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4"
                            >
                              {getEndDateStatus(item.endDate, item.status)}
                            </td>
                            <td
                              onClick={() => handleNavigate(item.taskID)}
                              className="px-6 py-4"
                            >
                              <div className="w-full bg-gray-200 rounded-lg h-2.5 ">
                                <div
                                  className="bg-slate-600 h-2.5 rounded-lg"
                                  style={{ width: `${item.intensityPercent}%` }}
                                ></div>
                              </div>
                            </td>
                            <td className="flex items-center px-3 py-4 space-x-3">
                              {item.status === 73 && (
                                <button
                                  onClick={() =>
                                    handleStatusChange(item.taskID)
                                  }
                                >
                                  <a
                                    className="font-medium text-[#0C692F] hover:underline cursor-pointer"
                                    title="Görevi Onayla"
                                  >
                                    <FaCheck
                                      className="hover:scale-110 transition-transform duration-200"
                                      size={24}
                                    />
                                  </a>
                                </button>
                              )}
                              {permissions.updatePermission && (
                                <Link
                                  to={`/tasksByCreatorUserEdit/${item.taskID}`}
                                >
                                  <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                    <TbEdit
                                      className="hover:scale-110 transition-transform duration-200"
                                      size={24}
                                    />
                                  </a>
                                </Link>
                              )}

                              <button
                                onClick={() => {
                                  handleAskStatus(item.taskID);
                                }}
                              >
                                <a
                                  className="font-medium text-[#050A27] hover:underline cursor-pointer"
                                  title="Durum rapooru iste"
                                >
                                  <FaQuestion
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={24}
                                  />
                                </a>
                              </button>
                              {permissions.deletePermission && (
                                <button
                                  onClick={() => {
                                    handleDelete(item.taskID, item.customerID);
                                  }}
                                >
                                  <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                    <RiDeleteBin6Line
                                      className="hover:scale-110 transition-transform duration-200"
                                      size={24}
                                    />
                                  </a>
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default TasksByCreatorUser;
