import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Switch from "react-switch";
import InputMask from "react-input-mask";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const UserEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [userID, setUserID] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [data, setData] = useState([]);
  //FORM
  const [customerID, setCustomerID] = useState(Number());
  const [isQualityCommitee, setIsQualityCommitee] = useState(false);
  const [roleID, setRoleID] = useState(Number());
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [google, setGoogle] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedJob, setSelectedJob] = useState({});

  const [userRole, setUserRole] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=9`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/User/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setData(res.data);
              setCustomerID(res.data.customerID);
              setUserName(res.data.userName);
              setName(res.data.name);
              setSurName(res.data.surname);
              setTelephone(res.data.telephone);
              setFacebook(res.data.facebook);
              setInstagram(res.data.instagram);
              setGoogle(res.data.google);
              setDescription(res.data.description);
              setEmail(res.data.email);
              setUserID(res.data.userID);
              setRoleID(res.data.roleID);
              setUserRole({ label: res.data.roleName, value: res.data.roleID });
              setIsQualityCommitee(res.data.isQualityCommitee);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/User/GetRoles?customerID=${user.country}`)
            .then((res) => {
              setRoles(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/User/GetJobs?customerID=${user.country}`)
            .then((res) => {
              setAllJobs(res.data);
              const dataJob = res.data;
              const newData = dataJob?.map((item) => ({
                value: item.jobID,
                label: item.name,
              }));
              setJobs(newData);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  //SELECT OPTIONS
  const rolesOptions = roles?.map((item) => ({
    label: item.name,
    value: item.roleID,
  }));

  const handleChange = (event) => {
    setFile(event.target.files);
  };

  const postData = {
    customerID,
    userID,
    roleID: userRole.value,
    jobID: selectedJob.value,
    userName,
    password,
    name,
    surname,
    telephone,
    facebook,
    instagram,
    google,
    description,
    imageFile,
    email,
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();

    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }

    api
      .put("/api/User/Put/Put", postData)
      .then((res) => {
        // Dosya yüklenmişse dosyaları yükle
        if (file && file.length > 0) {
          api
            .post(
              `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=13&relationID=${userID}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Kullanıcı güncellendi !",
                showConfirmButton: false,
                timer: 1500,
              });
              navigate("/users");
            })
            .catch((error) => {
              console.error(error);
              const errorMessageKey = error.response?.data;
              const translatedError = errorMessageKey
                ? t(`errors.${errorMessageKey}`, {
                    defaultValue: errorMessageKey,
                  })
                : t("errors.UnknownError", {
                    defaultValue: "Bilinmeyen bir hata",
                  });
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: `${translatedError}`,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else {
          // Dosya yüklenmemişse direkt olarak bildirim göster
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Kullanıcı güncellendi !",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/users");
        }
      })
      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (allJobs && data) {
      const matchedID = allJobs.find((item) => item.jobID === data.jobID);
      setSelectedJob({
        value: matchedID?.jobID,
        label: matchedID?.name,
      });
    }
  }, [data, allJobs]);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Kullanıcılar", href: "/users" },
                { label: "Kullanıcı Güncelle", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">
                  Kullanıcı Güncelle
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-3 gap-7 items-center w-full"
              >
                <div className="col-span-3">
                  <input
                    className="block w-54 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                    type="file"
                    onChange={handleChange}
                    multiple
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Sicil NO :
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    disabled
                    value={data?.registerNumber}
                  />
                </div>
                <div>
                  <label
                    htmlFor="userName"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kullanıcı Adı :
                  </label>
                  <input
                    type="text"
                    id="userName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Adı :
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="surname"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Soyadı :
                  </label>
                  <input
                    type="text"
                    id="surname"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={surname}
                    onChange={(e) => setSurName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    E-mail :
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="telephone"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Tel NO :
                  </label>
                  <InputMask
                    id="phoneNumber"
                    type="tel"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Telefon"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                    mask="(999) 999-9999" // Telefon numarası formatı burada belirtiliyor
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Şifre :
                  </label>
                  <input
                    type="password"
                    id="password"
                    placeholder="********"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="facebook"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Facebook :
                  </label>
                  <input
                    type="text"
                    id="facebook"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="instagram"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Instagram :
                  </label>
                  <input
                    type="text"
                    id="instagram"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="google"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Google :
                  </label>
                  <input
                    type="text"
                    id="google"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={google}
                    onChange={(e) => setGoogle(e.target.value)}
                  />
                </div>

                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <input
                    type="text"
                    id="description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="col-span-1">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Pozisyon :
                  </label>
                  <Select
                    value={selectedJob}
                    onChange={(e) => setSelectedJob(e)}
                    options={jobs}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="roles"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Rolü :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    onChange={(e) => setUserRole(e)}
                    value={userRole}
                    id="roles"
                    options={rolesOptions}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="flex items-center justify-end pt-5 gap-2">
                  <label
                    className="block text-sm font-medium text-gray-900"
                    htmlFor=""
                  >
                    Kalite Komitesi :
                  </label>
                  <Switch
                    checked={isQualityCommitee}
                    onChange={(e) => setIsQualityCommitee(e)}
                  />
                </div>
                {permissions.updatePermission && (
                  <div className="col-span-3 flex justify-end gap-[20px]">
                    <button
                      type="submit"
                      className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    >
                      Kaydet
                      <i className="fa-regular fa-floppy-disk ml-2"></i>
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default UserEdit;
