import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { FaRegCirclePlay } from "react-icons/fa6";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { BiTransfer } from "react-icons/bi";
import TaskTransferModal from "./TaskTransferModal";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const TasksByAssignedUser = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [matchedData, setMatchedData] = useState(null);
  const [matchedAllData, setMatchedAllData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [openMdule, setOpenModule] = useState(false);
  const [selectedTaskID, setSelectedTaskID] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=13`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(
              `/api/Task/GetTasksByAssignedUser?customerID=${user.country}&userID=${user.nameidentifier}`
            )
            .then((res) => setData(res.data))
            .catch((err) => console.log(err.data));
          api
            .get(
              `/api/Task/GetAllTasksByAssignedUser?customerID=${user.country}&userID=${user.nameidentifier}`
            )
            .then((res) => setAllData(res.data))
            .catch((err) => console.log(err.data));

          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => setUsers(res.data))
            .catch((err) => console.log(err.data));
          api
            .get(
              `/api/Project/GetProjects?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => setProjects(res.data.items))
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  useEffect(() => {
    if (data && projects && allData) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedUsers = data.map((item) => {
        const matchedProject = projects.find(
          (project) => project.projectID === item.projectID
        );
        if (matchedProject) {
          return {
            ...item,
            projectName: matchedProject.name,
          };
        }
        return item;
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setMatchedData(matchedUsers);

      ////////////////////// ALL DATA //////////////////////
      const matchedAllUsers = allData.map((item) => {
        const matchedProject = projects.find(
          (project) => project.projectID === item.projectID
        );
        if (matchedProject) {
          return {
            ...item,
            projectName: matchedProject.name,
          };
        }
        return item;
      });
      setMatchedAllData(matchedAllUsers);
    }
  }, [data, projects, allData]);

  const handleSubmit = async (id) => {
    Swal.fire({
      title: "Görevi başlatmak istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0C692F",
      cancelButtonColor: "#B10F0F",
      confirmButtonText: "Evet, başlat!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const storedToken =
            localStorage.getItem("token") || sessionStorage.getItem("token");

          if (!storedToken) {
            throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
          }

          await api.put(
            `/api/Task/PutTaskStatus?taskID=${id}&status=71`,
            null,
            {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            }
          );

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Görev başlatıldı!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (error) {
          const errorMessageKey = error.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${translatedError}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  function getPriorityFlag(priorityLevel) {
    switch (priorityLevel) {
      case 5:
        return <i className="fa-solid fa-flag text-red-600 fa-xl"></i>; // Kırmızı Bayrak
      case 4:
        return <i className="fa-solid fa-flag text-orange-400 fa-xl"></i>; // Turuncu Bayrak
      case 3:
        return <i className="fa-solid fa-flag text-yellow-400 fa-xl"></i>; // Sarı Bayrak
      case 2:
        return <i className="fa-solid fa-flag text-green-600 fa-xl"></i>; // Yeşil Bayrak
      case 1:
        return <i className="fa-solid fa-flag text-blue-600 fa-xl"></i>; // Mavi Bayrak
      default:
        return <i className="fa-solid fa-flag text-gray-600 fa-xl"></i>; // Varsayılan renk
    }
  }

  function getPriorityFlagClass(highPriority) {
    if (highPriority) {
      return <i className="fa-solid fa-check fa-xl text-green-600"></i>; // Kırmızı Bayrak
    } else {
      return null;
    }
  }
  const getEndDateStatus = (endDate, status) => {
    const today = new Date();
    const endTaskDate = new Date(endDate);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1); // Yarın

    if (status === 1522) {
      return <p className="text-green-600 font-medium">Tamamlandı</p>;
    } else if (status === 72) {
      return <p className="text-red-600 font-medium">İptal Edildi</p>;
    } else if (status === 73) {
      return <p className="text-yellow-600 font-medium">Onay Bekliyor</p>;
    } else if (endTaskDate.toDateString() === tomorrow.toDateString()) {
      return <p className="text-yellow-500 font-medium">Süre Dolmak Üzere</p>;
    } else if (endTaskDate > today) {
      return <p className="text-blue-600 font-medium">Devam Ediyor</p>;
    } else {
      return <p className="text-red-600 font-medium">Süre Doldu</p>;
    }
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="w-full mt-7">
          <div className="add-button">
            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title ">
                Atanmış Görevler
              </h1>
            </div>
            <div className="flex justify-between">
              <input
                className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                type="text"
                placeholder="Görev ara.."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <div className="flex gap-5">
                <label
                  className="block text-base font-medium text-gray-600"
                  htmlFor="isChecked"
                >
                  Biten ve iptal edilen görevleri göster
                </label>
                <Switch
                  id="isChecked"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e)}
                />
              </div>
            </div>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                <tr>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">
                    Görev Başlığı
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Görev Adı
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Yüksek Öncelikli
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Başlangıç Tarih
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Bitiş Tarih
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Atayan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Proje
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Durum
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tamamlanma Durumu
                  </th>
                  <th scope="col" className="px-6 py-3">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {isChecked
                  ? matchedAllData
                      ?.filter((item) =>
                        item.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => {
                        const rowClass =
                          item.status === 1522 ? "bg-gray-50" : "bg-white";
                        const rowText =
                          item.status === 1522 || item.status === 72
                            ? "line-through"
                            : "";
                        const highPriorityColor = item.highPriority
                          ? "bg-yellow-50"
                          : "bg-white";

                        return (
                          <tr
                            key={index}
                            className={`border-b hover:bg-gray-50 ${rowClass} ${highPriorityColor}`}
                          >
                            <td className="px-6 py-4">
                              {getPriorityFlag(item.priorityLevel)}
                            </td>
                            <th
                              scope="row"
                              className={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap ${rowText}`}
                              title={item.name}
                            >
                              {truncateText(item.name, 20)}
                            </th>
                            <td
                              title={item.title}
                              className={`px-6 py-4 ${rowText}`}
                            >
                              {truncateText(item.title, 25)}
                            </td>
                            <td className={`px-6 py-4 text-center`}>
                              {getPriorityFlagClass(item.highPriority)}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.startDate)}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.endDate)}
                            </td>
                            <td className="px-6 py-4">
                              {item.creatorUser.userName}
                            </td>
                            <td className="px-6 py-4">{item.projectName}</td>
                            <td className="px-6 py-4">
                              {getEndDateStatus(item.endDate, item.status)}
                            </td>
                            <td className="px-6 py-4">
                              <div className="w-full bg-gray-200 rounded-lg h-2.5 ">
                                <div
                                  className="bg-slate-600 h-2.5 rounded-lg"
                                  style={{ width: `${item.intensityPercent}%` }}
                                ></div>
                              </div>
                            </td>
                            <td className="flex items-center px-6 py-4 space-x-4">
                              <Link
                                to={`/taskDetailByAssignedUser/${item.taskID}`}
                              >
                                <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                  <FaRegEye
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={24}
                                  />
                                </a>
                              </Link>
                              <button
                                onClick={() => handleSubmit(item.taskID)}
                                type="button"
                              >
                                <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                  <FaRegCirclePlay
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={22}
                                  />
                                </a>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                  : matchedData
                      ?.filter((item) =>
                        item.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => {
                        const rowClass =
                          item.status === 1522 ? "bg-gray-50" : "bg-white";
                        const rowText =
                          item.status === 1522 ? "line-through" : "";
                        const highPriorityColor = item.highPriority
                          ? "bg-yellow-50"
                          : "bg-white";

                        return (
                          <tr
                            key={index}
                            className={`border-b hover:bg-gray-50 ${rowClass} ${highPriorityColor}`}
                          >
                            <td className="px-6 py-4">
                              {getPriorityFlag(item.priorityLevel)}
                            </td>
                            <th
                              scope="row"
                              className={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap ${rowText}`}
                              title={item.name}
                            >
                              {truncateText(item.name, 20)}
                            </th>
                            <td
                              title={item.title}
                              className={`px-6 py-4 ${rowText}`}
                            >
                              {truncateText(item.title, 25)}
                            </td>
                            <td className="px-6 py-4 text-center">
                              {getPriorityFlagClass(item.highPriority)}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.startDate)}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.endDate)}
                            </td>
                            <td className="px-6 py-4">
                              {item.creatorUser.userName}
                            </td>
                            <td className="px-6 py-4">{item.projectName}</td>
                            <td className="px-6 py-4">
                              {getEndDateStatus(item.endDate, item.status)}
                            </td>
                            <td className="px-6 py-4">
                              <div className="w-full bg-gray-200 rounded-lg h-2.5 ">
                                <div
                                  className="bg-slate-600 h-2.5 rounded-lg"
                                  style={{ width: `${item.intensityPercent}%` }}
                                ></div>
                              </div>
                            </td>
                            <td className="flex items-center px-3 py-4 space-x-4">
                              <Link
                                to={`/taskDetailByAssignedUser/${item.taskID}`}
                              >
                                <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                  <FaRegEye
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={24}
                                  />
                                </a>
                              </Link>
                              <button
                                onClick={() => handleSubmit(item.taskID)}
                                type="button"
                              >
                                <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                  <FaRegCirclePlay
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={22}
                                  />
                                </a>
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setOpenModule(true);
                                  setSelectedTaskID(item.taskID);
                                }}
                              >
                                <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                  <BiTransfer
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={27}
                                  />
                                </a>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {openMdule === true && (
        <TaskTransferModal
          onCancel={() => setOpenModule(false)}
          taskID={selectedTaskID}
        />
      )}
    </>
  );
};

export default TasksByAssignedUser;
