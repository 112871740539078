import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import { useTranslation } from "react-i18next";

const MeetingLogin = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [newData, setNewData] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=17`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/Meeting/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setData(res.data);
              const descData = res.data.meeting_Users.find(
                (item) => item.userID === Number(user.nameidentifier)
              );
              setDescription(descData.description);
            })
            .catch((err) => console.error(err.data));

          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => {
              setUsers(res.data);
            })
            .catch((err) => console.error(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  useEffect(() => {
    if (users && data.meeting_Users) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedUsers = data?.meeting_Users?.map((item) => {
        const matchedUser = users.find((user) => user.userID === item.userID);
        if (matchedUser) {
          return { ...item, userName: matchedUser.userName };
        }
        return item;
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setNewData(matchedUsers);
    }
  }, [users, data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.put(
        `/api/Meeting/PutUserDescription/PutUserDescription?meetingID=${params.id}&description=${description}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Not oluşturuldu!",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey 
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey }) 
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" }); 
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="mt-12  flex flex-col gap-[90px] p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[75%] bg-gray-100">
            <div className="flex justify-around gap-[20px]">
              {data.zoom_URL === null ? null : (
                <div className="w-[70vw]">
                  <iframe
                    height={500}
                    width={500}
                    style={{ width: "100%" }}
                    src={`${data?.zoom_URL}`}
                    allow="camera; microphone; display-capture"
                  ></iframe>
                </div>
              )}
              <div
                className={`bg-gray-50 overflow-y-scroll mr-5 ${
                  data?.zoom_URL ? "w-full" : "w-7/10"
                } max-h-[500px] min-h-[200px]`}
              >
                <ul className="text-sm font-medium text-blue-600 bg-white border border-gray-200 ">
                  <li className="w-full px-4 py-2 border-b border-gray-200  font-semibold text-2xl bg-gray-900 text-white">
                    Toplantı Gündem Maddeleri
                  </li>
                  {data?.meetingAgendaItems?.map((item, index) => (
                    <li
                      key={index}
                      className="w-full px-4 py-2 border-b border-gray-200 text-slate-600 text-lg"
                    >
                      <div className="flex gap-4">
                        <p className="text-slate-900 font-semibold">
                          {index + 1}.
                        </p>
                        <p>{item?.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex justify-between pr-[50px]">
              <div className="w-2/5 max-h-[300px] mr-5 overflow-y-scroll">
                <ul className="text-sm font-medium text-blue-600 bg-white border border-gray-200 ">
                  <li className="w-full px-4 py-2 border-b border-gray-200  font-semibold text-2xl bg-[#050A27] text-white">
                    Katılımcılar
                  </li>
                  {newData?.map((item) => (
                    <li className="w-full px-4 py-2 border-b border-gray-200 text-slate-600 text-lg">
                      <div className="flex justify-between">
                        <p>{item?.userName}</p>
                        {item?.status === 1 ? (
                          <span className="text-green-600 text-base">
                            Katıldı
                          </span>
                        ) : (
                          <span className="text-red-600 text-base">
                            Katılmadı
                          </span>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <form onSubmit={handleSubmit} className="w-[40vw]">
                <label
                  htmlFor="description"
                  className="block text-xl font-semibold text-[#050A27]"
                >
                  Not :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <button
                  type="submit"
                  className="text-white bg-[#0C692F] hover:bg-[#0c6930e3]  focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-1.5 focus:outline-none w-28 h-12"
                  title="Kaydet"
                >
                  Kaydet
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MeetingLogin;
