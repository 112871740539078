import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import left from "../../assets/images/left-click.png";
import right from "../../assets/images/right-click.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { setHours, setMinutes } from "date-fns";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";
import doc from "../../assets/images/docimg.png";
import { FaStarOfLife } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";

/// REACT SPLIDE
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const TasksByCreatorUserEdit = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [getData, setGetData] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [customerID, setCustomerID] = useState(Number());
  const [userID, setUserID] = useState(Number());
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [taskID, setTaskID] = useState(true);

  //FORM
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  const [endDate, setEndDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  const [accounts, setAccounts] = useState([]);
  const [accountID, setAccountID] = useState([]);
  const [highPriority, setHighPriority] = useState(false);
  const [customerKey, setCustomerKey] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectID, setProjectID] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [taskType, setTaskType] = useState([]);
  const [taskAllType, setTaskAllType] = useState([]);
  const [selectedTaskType, setSelectedTaskType] = useState({});
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [file, setFile] = useState(null);
  const [dataImages, setDataImages] = useState(null);

  //ASSIGNED USERS MODULE
  const [data, setData] = useState([]);
  const [assignedModules, setAssignedModules] = useState([]);
  const [preAssignedModules, setPreAssignedModules] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState(null);

  //TODOS
  const [taskTodos, setTaskTodos] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      setUserID(user.nameidentifier);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=13`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Task/GetSubUsers?customerID=${user.country}&userID=${user.nameidentifier}`
            )
            .then((res) => setUsers(res.data))
            .catch((err) => console.error(err.data));

          api
            .get(
              `/api/Project/GetProjects?customerID=${user.country}&pageNumber=1&pageSize=9999999`
            )
            .then((res) => {
              const dataProject = res.data.items;
              const newDataProject = dataProject.map((item) => ({
                value: item.projectID,
                label: item.name,
              }));
              setProjects(newDataProject);
            })
            .catch((err) => console.error(err.data));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Account/GetAccounts?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataAccount = res.data.items;
              const newDataAccount = dataAccount.map((item) => ({
                value: item.accountID,
                label: item.name,
              }));
              setAccounts(newDataAccount);
            })
            .catch((err) => {
              console.error(err.data);
            });

          api
            .get(`/api/Task/GetActiveTasks?customerID=${user.country}`)
            .then((res) => {
              setAllTasks(res.data);
              const dataTasks = res.data;
              const newDataTasks = dataTasks.map((item) => ({
                value: item.taskID,
                label: item.name,
              }));
              setTasks(newDataTasks);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Task/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setGetData(res.data);
              setDataImages(res.data.imageFiles);
              setTaskID(res.data.taskID);
              setEndDate(new Date(res.data.endDate));
              setStartDate(new Date(res.data.startDate));
              setRating(res.data.taskDifficulty);
              setTitle(res.data.title);
              setName(res.data.name);
              setHighPriority(res.data.highPriority);
              setDescription(res.data.description);
              setSelectedLocation({
                value: res.data.location,
                label: res.data.location,
              });
              setAccountID({
                value: res.data.account.accountID,
                label: res.data.account.name,
              });
              const data = JSON.parse(res.data.keywords);
              const newData = data.map((item) => ({
                value: item,
                label: item,
                __isNew__: true,
              }));
              setKeywords(newData);

              setAssignedUsers(res.data.tasks_AssignedUsers);
              setProjectID({
                value: res.data.project.projectID,
                label: res.data.project.name,
              });
            })
            .catch((err) => {
              console.error(err.data);
            });

          api
            .get(`/api/Common/GetLocations?customerID=${user.country}`)
            .then((res) => {
              let dataLocations = res.data;
              let newDataLocations = dataLocations.map((item) => ({
                value: item,
                label: item,
              }));
              setLocations(newDataLocations);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetParameters?parametername=TaskType`)
            .then((res) => {
              setTaskAllType(res.data);
              let dataTaskType = res.data;
              let newDataTaskType = dataTaskType.map((item) => ({
                value: item.parameterID,
                label: item.parameterValue2,
              }));
              setTaskType(newDataTaskType);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  /// DATE OPTIONS
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  /// ASSIGNED USERS MODEL OPTIONS
  const moveItemToAssigned = () => {
    if (selectedItemData && !assignedModules.includes(selectedItemData)) {
      const yeniOge = {
        assignmentID: "",
        userName: selectedItemData?.userName,
        userID: selectedItemData?.userID,
        customerID: selectedItemData?.customerID,
        taskID: "",
        status: 0,
        approvalUserID: 0,
        approvalDate: "",
        viewIndex: 0,
        type: 0,
      };

      setAssignedModules((oncekiModuller) => [...oncekiModuller, yeniOge]);
      setData((oncekiData) =>
        oncekiData.filter((oge) => oge !== selectedItemData)
      );
      setSelectedItemData(null);
    }
  };

  const moveItemToAllModules = () => {
    if (selectedItemData && !data.includes(selectedItemData)) {
      setData((prevData) => [...prevData, selectedItemData]);
      setAssignedModules((prevModules) =>
        prevModules.filter((item) => item !== selectedItemData)
      );
      setSelectedItemData(null);
    }
  };

  // TODO OPTIONS
  const handleServiceChange = (e, index) => {
    const { value } = e.target;
    const list = [...taskTodos];
    list[index].name = value;
    setTaskTodos(list);
  };

  const handleServiceAdd = () => {
    setTaskTodos([
      ...taskTodos,
      {
        toDoID: null,
        name: "",
        customerID,
        taskID: params.id,
        userID,
        isFinished: false,
        viewIndex: 0,
      },
    ]);
  };

  const handleServiceRemove = (index) => {
    const list = [...taskTodos];
    list.splice(index, 1);
    setTaskTodos(list);
  };

  // FILE ONCHANGE
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const postData = {
    customerID,
    taskID: params.id,
    parentID: 0,
    projectID: projectID.value,
    userID,
    priorityTaskID: selectedTask.value,
    revisionTaskID: 0,
    revisionNumber: 0,
    revisionDescription: "",
    accountID: null,
    document: "",
    name,
    title,
    startDate,
    endDate,
    location: selectedLocation.label,
    highPriority,
    intensityPercent: 0,
    taskDifficulty: 1,
    type: selectedTaskType.value,
    status: 0,
    viewIndex: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    todos: taskTodos.map((item) => item),

    assignedUsers: assignedModules.map((item) => ({
      assignmentID: item.assignmentID,
      customerID: item.customerID,
      userID: item.userID,
      taskID: item.taskID,
      status: item.status,
      approvalUserID: item.approvalUserID,
      approvalDate: item.approvalDate,
      viewIndex: item.viewIndex,
      type: item.type,
    })),
  };

  useEffect(() => {
    if (users && getData.tasks_AssignedUsers && allTasks && taskAllType) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedModules = getData.tasks_AssignedUsers.map((item) => {
        // Kullanıcıyı bul
        const matchedUser = users.find((user) => user.userID === item.userID);
        // Kullanıcının verilerini ve todoList'teki ilgili verileri kullanarak yeni bir nesne oluştur
        return {
          assignmentID: item.assignmentID,
          customerID: item.customerID,
          userID: item.userID,
          taskID: item.taskID,
          status: item.status,
          type: item.type,
          approvalUserID: item.approvalUserID,
          approvalDate: item.approvalDate,
          viewIndex: item.viewIndex,

          // Kullanıcı bilgilerini de ekle
          userName: matchedUser?.userName,
        };
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setAssignedModules(matchedModules);

      const newTaskTodos = getData.tasks_Todos.map((item) => ({
        toDoID: item.toDoID,
        name: item.name,
        customerID: item.customerID,
        taskID: item.taskID,
        userID: item.userID,
        isFinished: item.isFinished,
        viewIndex: item.viewIndex,
      }));
      setTaskTodos(newTaskTodos);

      const matchedTask = allTasks.find(
        (item) => item.taskID === getData.priorityTaskID
      );
      setSelectedTask({
        value: matchedTask?.taskID,
        label: matchedTask?.name,
      });

      const matchedTaskType = taskAllType.find(
        (item) => item.parameterID === getData.type
      );
      setSelectedTaskType({
        value: matchedTaskType?.parameterID,
        label: matchedTaskType?.parameterValue2,
      });
    }
  }, [users, getData.tasks_AssignedUsers, allTasks, taskAllType]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Görevi güncelle
      await api.put("/api/Task/Put", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      // Dosyaları yükle (eğer varsa)
      if (file && file.length > 0) {
        await api.post(
          `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=110&relationID=${taskID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Görev güncellendi !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/tasks");
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Görevler", href: "/tasks" },
                { label: "Görev Güncelle", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">Görev Güncelle</h1>
              </div>
              <form
                className="grid grid-cols-4 gap-7 items-center w-full"
                onSubmit={handleSubmit}
              >
                <div className="col-span-2">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Başlık :
                  </label>
                  <input
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="taskName"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Görev Adı :
                  </label>
                  <input
                    type="text"
                    id="taskName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="project"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Proje Seç :
                  </label>
                  <Select
                    id="project"
                    value={projectID}
                    onChange={(e) => setProjectID(e)}
                    options={projects}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="priorityTaskID"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Öncelikli Görev :
                  </label>
                  <Select
                    value={selectedTask}
                    onChange={(e) => setSelectedTask(e)}
                    options={tasks}
                    id="priorityTaskID"
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="taskName"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Anahtar Kelimeler :
                  </label>
                  <CreatableSelect
                    onChange={(e) => setKeywords(e)}
                    options={customerKey}
                    isMulti
                    maxMenuHeight={200}
                    value={keywords}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>

                <div className="col-span-1">
                  <label
                    htmlFor="startDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Başlangıç Tarih :
                  </label>
                  <DatePicker
                    id="startDate"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    locale={tr}
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="endDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Bitiş Tarih :
                  </label>
                  <DatePicker
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    showTimeSelect
                    locale={tr}
                    filterTime={filterPassedTime}
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>

                <div>
                  <label
                    htmlFor="telephone"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Lokasyon :
                  </label>
                  <Select
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e)}
                    options={locations}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="taskType"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Görev Tipi :
                  </label>
                  <Select
                    value={selectedTaskType}
                    onChange={(e) => setSelectedTaskType(e)}
                    options={taskType}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="col-span-3">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="multiple_files"
                  >
                    Dosyaları Yükle
                  </label>
                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                    id="multiple_files"
                    type="file"
                    multiple
                    onChange={handleChangeFile}
                  />
                </div>
                <div className="flex justify-end items-center gap-2 col-span-1">
                  <label
                    htmlFor="highPriority"
                    className="block text-lg font-medium text-gray-900"
                  >
                    Yüksek Öncelikli :
                  </label>
                  <Switch
                    checked={highPriority}
                    onChange={(e) => setHighPriority(e)}
                  />
                </div>

                <div className="col-span-4">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <textarea
                    id="description"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className="col-span-4 my-12">
                  <Splide
                    options={{
                      autoWidth: true,
                      gap: 100,
                      pagination: false,
                      padding: 100,
                    }}
                    aria-label="My Favorite Images"
                  >
                    {dataImages?.map((item, index) => {
                      const imageName = item.split("/").pop();
                      const altText = imageName.split("_").pop().split(".")[0];
                      // Resim URL'si kontrolü
                      const isImage = /\.(jpg|jpeg|png|gif)$/i.test(item);
                      return (
                        <SplideSlide key={index}>
                          {isImage ? (
                            <Link to={item}>
                              <img
                                className="max-w-[200px] cursor-pointer"
                                src={item}
                                alt={`Image ${index + 1}`}
                              />
                            </Link>
                          ) : (
                            <Link to={item}>
                              <img
                                className="max-w-[100px] cursor-pointer"
                                src={`${doc}`}
                              />
                            </Link>
                          )}
                          <p className="text-center font-medium">{altText}</p>
                        </SplideSlide>
                      );
                    })}
                  </Splide>
                </div>

                <div className="flex gap-16 h-full mt-16 col-span-4">
                  <div className="origin-top-right right-0 mt-2 w-64 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none col-span-3 overflow-y-scroll max-h-[500px] min-w-[300px] min-h-[300px]">
                    <h1 className="px-4 py-1 text-lg font-medium text-white bg-[#050A27] sticky top-0">
                      Tüm Kullanıcılar
                    </h1>
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {users
                        .filter(
                          (item) =>
                            !preAssignedModules.some(
                              (assignedItem) =>
                                assignedItem.userID === item.userID
                            ) &&
                            !assignedModules.some(
                              (assignedItem) =>
                                assignedItem.userID === item.userID
                            )
                        )
                        .map((item, index) => (
                          <button
                            key={index}
                            className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            role="menuitem"
                            type="button"
                            onClick={() => setSelectedItemData(item)}
                          >
                            {item.userName}
                          </button>
                        ))}
                    </div>
                  </div>
                  <div className="flex flex-col gap-7 justify-center">
                    <button
                      onClick={moveItemToAssigned}
                      type="button"
                      className="w-12 hover:scale-110 transition-transform duration-200"
                    >
                      <FaArrowRightLong color="green" size={50} />
                    </button>
                    <button
                      onClick={moveItemToAllModules}
                      type="button"
                      className="w-12 hover:scale-110 transition-transform duration-200"
                    >
                      <FaArrowLeftLong color="#E4003A" size={50} />
                    </button>
                  </div>
                  <div className="origin-top-right right-0 mt-2 w-64 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll max-h-[500px] min-w-[300px]">
                    <h1 className="px-4 py-1 text-lg font-medium text-white bg-[#050A27] sticky top-0">
                      Atanacak Kullanıcılar
                    </h1>
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {assignedModules.map((item) => (
                        <button
                          key={item.userID}
                          type="button"
                          className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                          role="menuitem"
                          onClick={() => setSelectedItemData(item)}
                        >
                          {item.userName}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="relative overflow-x-auto mb-5 w-full overflow-y-auto max-h-[500px]">
                    <table className="w-full text-sm text-left text-gray-500">
                      <thead className="text-xs text-white uppercase bg-[#050A27]">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Yapılacaklar Listesi
                          </th>
                          <th scope="col" className="px-6 py-3">
                            İslemler
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskTodos.map((singleService, index) => (
                          <tr key={index} className="bg-white border-b">
                            <td className="px-6 py-4">
                              <input
                                name="taskTodo"
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                required
                                onChange={(e) => handleServiceChange(e, index)}
                                value={singleService.name}
                              />
                            </td>
                            <td className="px-6 py-4">
                              <button
                                onClick={() => handleServiceRemove(index)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                              >
                                <i className="fa-regular fa-trash-can"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <button
                        type="button"
                        className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg my-5 h-10 w-24"
                        onClick={handleServiceAdd}
                      >
                        Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                    </table>
                  </div>
                </div>

                <div className="col-span-4 flex justify-end gap-[20px]">
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default TasksByCreatorUserEdit;
