import React, { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import exampleoperation from "../../assets/example_operation.xlsx";
import api from "../../api/api";
import Swal from "sweetalert2";
import LoadingSpinnerModal from "../LoadingSpinnerModal";
import { useTranslation } from "react-i18next";

const OperationModal = ({ onCancel = () => {} }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(Number());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
    }
    setLoading(false);
  }, [user]);

  // FILE ONCHANGE
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();

    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      //   Dosyaları yükle (eğer varsa)
      if (file && file.length > 0) {
        await api.post(
          `/api/Operation/PostExcelToOperations/PostExcelToOperations?customerID=${customerID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Dosya yüklendi !",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey 
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey }) 
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" }); 
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
      onCancel();
      // Belirli bir süre sonra sayfa yenilensin
      setTimeout(() => {
        window.location.reload();
      }, 1500); // 1500 milisaniye (1.5 saniye) sonra yenile
    }
  };
  return (
    <div className="absolute inset-0 w-screen h-screen bg-black/30 z-50 flex justify-center items-center">
      <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
        {loading ? (
          <LoadingSpinnerModal loading={loading} />
        ) : (
          <form
            className="grid grid-cols-2 gap-2 items-center"
            onSubmit={handleSubmit}
          >
            <div className="col-span-2">
              <h1 className="text-center  text-4xl font-bold leading-none tracking-tight text-blue-800">
                Excel Dosyası Yükle
              </h1>
            </div>
            <div className="col-span-2">
              <label
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                htmlFor="multiple_files"
              >
                Dosya Yükle
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                id="multiple_files"
                type="file"
                accept=".xls,.xlsx"
                onChange={handleChangeFile}
                required
              />
            </div>
            <div className="col-span-2">
              <a
                className="cursor-pointer font-medium"
                href={exampleoperation}
                download="OrnekDosya.xlsx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Örnek Excel Dosyası İndir!
              </a>
            </div>

            <div className="col-span-2 flex justify-center gap-[20px]">
              <button
                onClick={onCancel}
                className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              >
                Vazgeç
              </button>
              <button
                type="submit"
                className="bg-[#0C692F] hover:bg-[#0c6930d3] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              >
                Onayla
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default OperationModal;
