import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [traceCertificateNumber, setTraceCertificateNumber] = useState(false);
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [sectorName, setSectorName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [webUrl, setWebUrl] = useState("");

  useEffect(() => {
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=21`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/Customer/Get?id=${user.country}`)
            .then((res) => {
              setData(res.data);
              setName(res.data.name);
              setTitle(res.data.title);
              setSectorName(res.data.sectorName);
              setTelephone(res.data.telephone);
              setCellphone(res.data.cellphone);
              setAddress(res.data.address);
              setEmail(res.data.email);
              setWebUrl(res.data.webUrl);
              setTraceCertificateNumber(res.data.traceCertificateNumber);
            })
            .catch((err) => console.error(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const postData = {
    name,
    title,
    sectorName,
    telephone,
    cellphone,
    address,
    email,
    webUrl,
    traceCertificateNumber,
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .put("/api/Customer/Put/Put", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Ayarlar güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/stockCards");
      })

      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => setLoading(false));
  };

  console.log(postData);
  
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb breadcrumbData={[{ label: "Ayarlar", href: "" }]} />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">Ayarlar</h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-2 gap-7 items-center w-full"
              >
                <div>
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Başlık :
                  </label>
                  <input
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Adı :
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="sector"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Sektör :
                  </label>
                  <input
                    type="text"
                    id="sector"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={sectorName}
                    onChange={(e) => setSectorName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="telephone"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Tel. NO :
                  </label>
                  <input
                    type="number"
                    id="telephone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="cellphone"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Cep NO :
                  </label>
                  <input
                    type="number"
                    id="cellphone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={cellphone}
                    onChange={(e) => setCellphone(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="adress"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Adres :
                  </label>
                  <input
                    type="text"
                    id="adress"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    E-mail :
                  </label>
                  <input
                    type="text"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="weburl"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Web :
                  </label>
                  <input
                    type="text"
                    id="weburl"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={webUrl}
                    onChange={(e) => setWebUrl(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor=""
                  >
                    Yarı Mamullerde Sertifika No İzleme :
                  </label>
                  <Switch
                    checked={traceCertificateNumber}
                    onChange={(e) => setTraceCertificateNumber(e)}
                  />
                </div>
                <div className="col-span-2 flex justify-end gap-[20px]">
                  <Link to={"/organizationSchema"}>
                    <button
                      type="button"
                      className="butonCl text-white bg-[#050A27] hover:bg-[#050a27d7] focus:outline-none focus:ring-4 focus:ring-[#050A27] font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    >
                      Organizasyon Şemasını Gör
                      <i className="fa-regular fa-eye ml-2"></i>
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Settings;
