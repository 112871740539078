
import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';

const options = [
  {
    value: '1',
    label: 'Oluşturma',
  },
  {
    value: '2',
    label: 'Güncelleme',
  },
  {
    value: '3',
    label: 'Silme',
  },

];

function Select({ value, handleId, nodeId }) {

  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (evt) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            selects: {
              ...node.data.selects,
              [handleId]: evt.target.value,
            },
          };
        }

        return node;
      })
    );
  };

  return (
    <div className="custom-node__select">
      <Handle style={{top:"24px", left:"-15px",width:"6px", height:"10px",borderRadius:"2px",backgroundColor:"#778899"}} type='target' position={Position.Left} id={handleId}/>
      <div style={{fontSize:"12px", fontWeight:"500"}}>Seçiniz</div>
      <select className="nodrag" onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <Handle style={{top:"24px", right:"-15px",width:"6px", height:"10px",borderRadius:"2px",backgroundColor:"#778899"}} type="source" position={Position.Right} id={handleId} />
    </div>
  );
}

function CustomNodeMeeting({ id, data }) {
  return (
    <>
   <div style={{backgroundColor:"#f5f5f6",borderRadius:"10px"}}>
   <div className="custom-node__header">
         <strong>{data.label}</strong>
      </div>
      <div className="custom-node__body">
        {Object.keys(data.selects).map((handleId) => (
          <Select key={handleId} nodeId={id} value={data.selects[handleId]} handleId={handleId} />
        ))}
      </div>
   </div>
    </>
  );
}

export default memo(CustomNodeMeeting);
