import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";

const PersonelGroups = () => {
  const { user } = useAuth();
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=25`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/Personel/GetPersonGroups?customerID=${user.country}`)
            .then((res) => {
              setData(res.data);
            })
            .catch((err) => console.error(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleDelete = async (personGroupID) => {
    try {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      Swal.fire({
        title: "Personel grubunu silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor:"#0C692F",
        cancelButtonColor:"#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Personel/DeletePersonGroup/${personGroupID}?customerID=${customerID}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.personGroupID !== personGroupID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Personel grubu silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting project:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Personel grubu silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };
  console.log("data", data);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
          <Breadcrumb
             breadcrumbData={[
               { label: "Personeller", href: "/personels" },
               { label: "Personel Grupları", href: "" },
             ]}
           />
          <div className="m-12 min-h-screen">
            <div className="add-button">
              {permissions.insertPermission && (
                <Link to={"/personelGroupAdd"}>
                  <button
                    type="button"
                     className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-72"
                  >
                    Personel Grubu Oluştur
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </Link>
              )}
               <div className="mb-5 flex items-center gap-2">
               <FaStarOfLife size={15}/>
                <h1 className="text-3xl font-semibold title">
                  Personel Grupları
                </h1>
              </div>
              <div className="flex">
                <input
                  className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                  type="text"
                  placeholder="Personel Grubu ara.."
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Grup Adı
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Başlık
                    </th>
                    <th scope="col" className="px-6 py-3">
                      İşlemler
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data
                      ?.filter(
                        (item) =>
                          item.name
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.title
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b hover:bg-gray-50"
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {item?.name}
                          </th>
                          <td className="px-6 py-4">{item?.title}</td>
                          <td className="flex items-center px-6 py-4 space-x-3">
                            {permissions.reportPermission && (
                              <Link
                                to={`/personelGroupDetail/${item.personGroupID}`}
                              >
                                <a className="font-medium text-[#050A27] hover:underline">
                                  <FaRegEye className="hover:scale-110 transition-transform duration-200" size={24} />
                                </a>
                              </Link>
                            )}

                            {permissions.updatePermission && (
                              <Link
                                to={`/personelGroupEdit/${item.personGroupID}`}
                              >
                                {" "}
                                <a className="font-medium text-[#050A27] hover:underline">
                                  <TbEdit className="hover:scale-110 transition-transform duration-200" size={24} />
                                </a>
                              </Link>
                            )}

                            {permissions.deletePermission && (
                              <button
                                onClick={() => {
                                  handleDelete(item.personGroupID);
                                }}
                              >
                                <a className="font-medium text-[#050A27] hover:underline">
                                  <RiDeleteBin6Line className="hover:scale-110 transition-transform duration-200" size={24} />
                                </a>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td
                        colSpan={3}
                        className="px-6 py-4 text-center text-2xl font-medium"
                      >
                        Henüz kayıtlı bir veri yoktur.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
    </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PersonelGroups;
