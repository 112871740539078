import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api from "../../api/api";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const OfferEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [demandItems, setDemandItems] = useState([
    {
      quantity: Number(),
      unitPrice: Number(),
      discount: Number(),
      name: "",
      taxRate: "",
      totalPrice: Number(),
      price: "",
      taxPrice: "",
      description: "",
      offerItemID: Number(),
      offerID: Number(),
      customerID: Number(),
      stockCardID: Number(),
    },
  ]);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [vat, setVat] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [stockCards, setStockCards] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [title, setTitle] = useState("");
  const [offerName, setOfferName] = useState("");
  const [editUser, setEditUser] = useState([]);
  const [token, setToken] = useState(null);
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [parentNumber, setParentNumber] = useState(null);
  const [revisionOffers, setRevisionOffers] = useState([]);

  const [name, setName] = useState({
    value: "",
    label: "",
  });
  const [currencyValue, setCurrencyValue] = useState({
    value: "TL",
    label: "TL",
  });

  useEffect(() => {
    setLoading(true);
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=4`
        )
        .then((res) => {
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboards");
              }
            });
            return;
          }

          // İlk API çağrısı ve gerekli verilerin yüklenmesi
          Promise.all([
            api.get(
              `/api/Account/GetAccounts?customerID=${user.country}&pageNumber=1&pageSize=99999`
            ),
            api.get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&typeID=28&pageNumber=1&pageSize=99999`
            ),
            api.get(`/api/Common/GetKeywords?customerID=${user.country}`),
            api.get("/api/Common/GetVat"),
            api.get("/api/Common/GetCurrencies"),
            api.get(
              `/api/Offer/Get?id=${params.id}&customerID=${user.country}`
            ),
          ])
            .then(
              ([
                accountsRes,
                stockCardsRes,
                keywordsRes,
                vatRes,
                currenciesRes,
                offerRes,
              ]) => {
                setAccounts(accountsRes.data.items);
                setStockCards(stockCardsRes.data.items);

                const keywordsData = keywordsRes.data?.map((item) => ({
                  value: item,
                  label: item,
                }));
                setCustomerKey(keywordsData);

                setVat(vatRes.data);

                const currencyData = currenciesRes.data?.map((item) => ({
                  value: item.currencyID,
                  label: item.name,
                }));
                setCurrency(currencyData);

                // Teklif detaylarını güncelle
                setTitle(offerRes.data.title);
                setOfferName(offerRes.data.name);
                setCurrencyValue({
                  label: offerRes.data.currencyName,
                  value: offerRes.data.currencyID,
                });
                setName({
                  value: offerRes.data.account.name,
                  label: offerRes.data.account.name,
                });
                setDemandItems(offerRes.data.offerItems);
                setParentNumber(offerRes.data.parentID); // parentNumber'ı kaydediyoruz
                setEditUser(offerRes.data);
                setStartDate(new Date(offerRes.data.offerDate));
                setFinishDate(new Date(offerRes.data.expiryDate));
                setDescription(offerRes.data.description);

                const keywordItems = JSON.parse(offerRes.data.keywords).map(
                  (item) => ({ value: item, label: item, __isNew__: true })
                );
                setKeywords(keywordItems);
              }
            )
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err));
    }
  }, [user, params.id]);

  useEffect(() => {
    // `user` ve `user.country` mevcutsa işlem yapıyoruz
    if (user && user.country) {
      const offerID = parentNumber || params.id; // parentNumber varsa onu, yoksa params.id'yi kullan

      if (offerID) {
        api
          .get(
            `/api/Offer/GetOfferRevisionSummary?customerID=${user.country}&offerID=${offerID}`
          )
          .then((revisionOffersRes) => {
            setRevisionOffers(revisionOffersRes.data || []); // Boş dizi olarak güncelliyoruz
          })
          .catch((err) => console.log(err));
      }
    }
  }, [parentNumber, params.id, user]); // `user`, `parentNumber`, veya `params.id` değiştiğinde tetiklenir

  const optionsVat = vat.map((item) => ({
    value: item,
    label: item,
  }));

  const optionsCurrency = currency.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const stockCardName = stockCards.map((item) => ({
    value: item.name,
    label: `${item.code} - ${item.name}`,
  }));

  const contactName = accounts.map((item) => ({
    value: item.contactName,
    label: item.contactName,
  }));

  //ADD ITEMS///

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...demandItems];
    list[index][name] = value;

    //totalPrice
    let total =
      list[index].quantity * list[index].unitPrice - list[index].discount;
    let tax = (total * list[index].taxRate) / 100;
    let result = total + tax;
    list[index].totalPrice = result;

    //ara toplam
    let altToplam = list[index].quantity * list[index].unitPrice;
    list[index].price = altToplam;

    //KDV
    list[index].taxPrice = tax;

    setDemandItems(list);
  };

  const handleSelectChange = (e, index) => {
    const list = [...demandItems];
    list[index].name = e;
    const stockCardsValue = stockCards.find((item) => item.name === e);
    list[index].stockCardID = stockCardsValue.stockCardID;
    list[index].unitTypeString = stockCardsValue.unitTypeString;
    list[index].unitTypeString = stockCardsValue.unitTypeString;
    setDemandItems(list);
  };
  const handleSelectChangeVat = (e, index) => {
    const list = [...demandItems];
    list[index].taxRate = e;

    //totalPrice
    let total =
      list[index].quantity * list[index].unitPrice - list[index].discount;
    let tax = (total * list[index].taxRate) / 100;
    let result = total + tax;
    list[index].totalPrice = result;

    //KDV
    list[index].taxPrice = tax;

    setDemandItems(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...demandItems];
    list.splice(index, 1);
    setDemandItems(list);
  };

  const handleServiceAdd = () => {
    setDemandItems([
      ...demandItems,
      {
        quantity: Number(),
        unitPrice: Number(),
        discount: Number(),
        name: "",
        taxRate: "",
        totalPrice: Number(),
        price: "",
        taxPrice: "",
        description: "",
        offerItemID: Number(),
        offerID: Number(),
        customerID: Number(),
        stockCardID: Number(),
      },
    ]);
  };

  //////////////////////////

  const find = accounts.find((item) => item.name === name.value);

  ////////calculations
  const resultDiscount = demandItems?.length
    ? demandItems
        .map((sayi) => Number(sayi.discount))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultTotalPrice = demandItems?.length
    ? demandItems
        .map((sayi) => Number(sayi.totalPrice))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultSubTotal = demandItems?.length
    ? demandItems
        .map((sayi) => Number(sayi.price))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultKdvTotal = demandItems?.length
    ? demandItems
        .map((sayi) => Number(sayi.taxPrice))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const putData = {
    offerID: editUser?.offerID,
    customerID: find?.customerID,
    accountID: find?.accountID,
    currencyID: currencyValue?.value,
    offerNo: "",
    name: offerName,
    title: title,
    address: find?.address,
    taxOffice: find?.taxOffice,
    taxNumber: find?.taxNumber,
    offerDate: startDate,
    expiryDate: finishDate,
    price: resultSubTotal,
    lineDiscounts: resultDiscount,
    taxPrice: resultKdvTotal,
    totalPrice: resultTotalPrice,
    type: 0,
    status: 0,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    offerItems: demandItems,
  };
  const postData = {
    parentID: parentNumber || params.id,
    customerID: find?.customerID,
    accountID: find?.accountID,
    currencyID: currencyValue?.value,
    offerNo: "",
    name: offerName,
    title: title,
    address: find?.address,
    taxOffice: find?.taxOffice,
    taxNumber: find?.taxNumber,
    offerDate: startDate,
    expiryDate: finishDate,
    price: resultSubTotal,
    lineDiscounts: resultDiscount,
    taxPrice: resultKdvTotal,
    totalPrice: resultTotalPrice,
    type: 0,
    status: 0,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    offerItems: demandItems,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // İlk olarak onay modalını açıyoruz
    Swal.fire({
      title: "Revizyon olarak kayıt edilsin mi?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: "#0C692F",
      cancelButtonColor: "#B10F0F",
      confirmButtonText: "Evet",
      denyButtonText: "Hayır",
      denyButtonColor: "#050A27",
      cancelButtonText: "Kapat",
    }).then((result) => {
      // Eğer kullanıcı "Save" butonuna basarsa, API çağrısını yapıyoruz
      if (result.isDenied) {
        setLoading(true); // Yükleniyor durumunu açıyoruz

        api
          .put("/api/Offer/Put/Put", putData) // API'yi çağırıyoruz
          .then((res) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Teklif başarılı bir şekilde değiştirildi!",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((err) => {
            console.log(err);
            const errorMessageKey = err.response?.data;
            const translatedError = errorMessageKey
              ? t(`errors.${errorMessageKey}`, {
                  defaultValue: errorMessageKey,
                })
              : t("errors.UnknownError", {
                  defaultValue: "Bilinmeyen bir hata",
                });
            Swal.fire(translatedError); // Hata durumunda modal gösteriyoruz
          })
          .finally(() => setLoading(false)); // Yükleniyor durumunu kapatıyoruz
      } else if (result.isConfirmed) {
        // Eğer kullanıcı "Hayır" butonuna basarsa
        api
          .post("/api/Offer/Post/Post", postData) // API'yi çağırıyoruz
          .then((res) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Teklif başarılı bir şekilde revize edildi!",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((err) => {
            console.log(err);
            const errorMessageKey = err.response?.data;
            const translatedError = errorMessageKey
              ? t(`errors.${errorMessageKey}`, {
                  defaultValue: errorMessageKey,
                })
              : t("errors.UnknownError", {
                  defaultValue: "Bilinmeyen bir hata",
                });
            Swal.fire(translatedError); // Hata durumunda modal gösteriyoruz
          })
          .finally(() => setLoading(false));
      }
    });
  };

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    return `${gun}/${ay}/${yil}`;
  };
  console.log(parentNumber);
  console.log("postdata", postData);
  console.log("putdata", putData);

  return (
    <>
      <Header />

      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Teklifler", href: "/offers" },
                { label: "Teklif Güncelle", href: "" },
              ]}
            />
            <div className=" mx-auto my-20 rounded-[3px] min-h-screen flex justify-between w-[95%] gap-9">
              <form
                onSubmit={handleSubmit}
                className="w-[67%] bg-gray-100 p-12"
              >
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title ">
                    Teklif Güncelle
                  </h1>
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="title"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlık
                    </label>
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      id="title"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="offerName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adı
                    </label>
                    <input
                      value={offerName}
                      onChange={(e) => setOfferName(e.target.value)}
                      type="text"
                      id="offerName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Cari
                    </label>
                    <Select
                      maxMenuHeight={200}
                      id="name"
                      onChange={(e) => setName(e)}
                      value={name}
                      options={contactName}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="taxOffice"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Vergi Dairesi
                    </label>
                    <input
                      value={find?.taxOffice}
                      readOnly
                      type="text"
                      id="taxOffice"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Vergi No
                    </label>
                    <input
                      value={find?.taxNumber}
                      readOnly
                      type="text"
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler
                    </label>
                    <CreatableSelect
                      value={keywords}
                      onChange={(e) => setKeywords(e)}
                      options={customerKey}
                      isMulti
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>

                  <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label
                        htmlFor="visitors"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Para Birimi
                      </label>
                      <Select
                        maxMenuHeight={200}
                        onChange={(e) => setCurrencyValue(e)}
                        value={currencyValue}
                        options={currency}
                        classNamePrefix="custom-select"
                        placeholder="Seçiniz..."
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama
                    </label>
                    <input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      id="description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Tarih
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Ödeme Tarihi
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={finishDate}
                      onChange={(date) => setFinishDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                </div>

                <div className="relative overflow-x-auto mb-5">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün Adı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Miktar
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim Fiyat
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim Tipi
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İskonto
                        </th>
                        <th scope="col" className="px-6 py-3">
                          KDV
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Toplam
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İşlem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {demandItems.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-3 py-4">
                            <div className="w-[180px]">
                              <Select
                                maxMenuHeight={200}
                                value={{
                                  label: singleService.name,
                                  value: singleService.name,
                                }}
                                onChange={(e) =>
                                  handleSelectChange(e.value, index)
                                }
                                options={stockCardName}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..."
                              />
                            </div>
                          </td>

                          <td className="px-3 py-4">
                            <input
                              value={singleService.quantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="quantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.unitPrice}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="unitPrice"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            {singleService.unitTypeString ? (
                              <p className="text-[#0C692F] font-semibold text-center">
                                {singleService.unitTypeString}
                              </p>
                            ) : (
                              <p className="text-[#0C692F] font-semibold text-center">
                                -
                              </p>
                            )}
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.discount}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="discount"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-3 py-4">
                            <div className="min-w-[80px]">
                              <Select
                                maxMenuHeight={200}
                                value={{
                                  value: singleService.taxRate,
                                  label: singleService.taxRate,
                                }}
                                onChange={(e) =>
                                  handleSelectChangeVat(e.value, index)
                                }
                                options={optionsVat}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..."
                              />
                            </div>
                          </td>
                          <td className="px-3 py-4 ">
                            <input
                              value={singleService.totalPrice}
                              readOnly
                              name="totalPrice"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-3 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-2 px-4 rounded-lg m-5"
                              title="Sil"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <button
                      onClick={handleServiceAdd}
                      type="button"
                      className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg my-5 h-10 w-24"
                      title="Ekle"
                    >
                      Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </table>

                  <div className="resultMoney">
                    <div className="resultMoneyInfo">
                      <h1>Ara Toplam :</h1>
                      <p>{resultSubTotal}</p>
                      <span>{currencyValue.label}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>Satır İndirimi :</h1>
                      <p>{resultDiscount}</p>
                      <span>{currencyValue.label}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>KDV :</h1>
                      <p>{resultKdvTotal}</p>
                      <span>{currencyValue.label}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>Toplam :</h1>
                      <p>{resultTotalPrice}</p>
                      <span>{currencyValue.label}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    title="Kaydet"
                  >
                    Kaydet <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
              <div className="w-[33%] ">
                <div className="flex items-center gap-2 mb-8">
                  <FaStarOfLife size={15} />
                  <h1 className="font-semibold text-2xl ">Teklif Geçmişi</h1>
                </div>
                <div className="relative overflow-x-auto w-full">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs bg-[#050A27] text-white uppercase">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Başlık
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Tarih
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Fiyat
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Eğer offers mevcutsa Ana Teklif ve Revizyon tekliflerini göster */}
                      {revisionOffers?.offers?.length > 0 ? (
                        <>
                          {/* Ana Teklif Satırı */}
                          <tr
                            className={` cursor-pointer hover:bg-gray-100 ${
                              revisionOffers.offerID === parseInt(params.id)
                                ? "bg-gray-200"
                                : "bg-white"
                            }`}
                            onClick={() =>
                              navigate(`/offerEdit/${revisionOffers.offerID}`)
                            } // Yönlendirme işlemi
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <div>
                                Ana Teklif <br />
                                {revisionOffers?.title}
                              </div>
                            </th>
                            <td className="px-6 py-4">
                              {formatDate(revisionOffers?.offerDate)}
                            </td>
                            <td className="px-6 py-4 font-semibold">
                              {revisionOffers?.totalPrice}
                            </td>
                          </tr>

                          {/* Revizyon Teklifleri */}
                          {revisionOffers.offers.map((item) => {
                            // Find the selected offer based on params.id
                            const isSelected =
                              item.offerID === parseInt(params.id);

                            return (
                              <tr
                                key={item.offerID}
                                className={` hover:bg-gray-100 cursor-pointer ${
                                  isSelected ? "bg-gray-200" : "bg-white"
                                }`}
                                onClick={() =>
                                  navigate(`/offerEdit/${item.offerID}`)
                                } // Yönlendirme işlemi
                              >
                                <th
                                  scope="row"
                                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                >
                                  <div>
                                    {`Revizyon ${item?.version}`} <br />
                                    {item?.title}
                                  </div>
                                </th>
                                <td className="px-6 py-4">
                                  {formatDate(item?.offerDate)}
                                </td>
                                <td className="px-6 py-4 font-semibold">
                                  {item?.totalPrice}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        // Eğer offers yoksa bilgi satırını göster
                        <tr>
                          <td
                            colSpan="3"
                            className="px-6 py-4 text-center text-gray-500"
                          >
                            Henüz revizyon kaydı bulunmamaktadır.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default OfferEdit;
