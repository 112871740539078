import React, { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { FaStar } from "react-icons/fa";
import LoadingSpinnerModal from "../LoadingSpinnerModal";

const FormModal = ({
  onChangeShowLink = () => {},
  stockCardID,
  onCancel = () => {},
}) => {
  const { user } = useAuth();
  const [customerID, setCustomerID] = useState(Number());
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [token, setToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1; // Number of items to show per page
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);

      api
        .get(
          `/api/WayBill/GetWorkFlowTriggerForWayBill?customerID=${user.country}&stockCardID=${stockCardID}`
        )
        .then((res) => setData(res.data))
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleSave = async (e) => {
    e.preventDefault();
    // setLoading(true); // İşleme başlarken yüklenme durumunu true yapar
    onChangeShowLink();
    // try {
    //   const response = await api
    //     .post
    //     //   `/api/WareHouse/PostShippingSlipQualityControl/PostShippingSlipQualityControl?shippingSlipID=${shippingSlipID}&customerID=${customerID}`
    //     ();

    //   // Başarılı işlem sonrası yapılacaklar
    //   console.log("Data saved successfully:", response.data);
    //   Swal.fire({
    //     position: "top-end",
    //     icon: "success",
    //     title: "İşlem başarılı!",
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    //   navigate("/shippingSlips");
    // } catch (error) {
    //   console.error("Hata alındı: ", error);
    //   Swal.fire({
    //     position: "top-end",
    //     icon: "error",
    //     title: `${error}`,
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    // } finally {
    //   setLoading(false); // İşlem tamamlandıktan sonra yüklenme durumunu false yapar
    // }
  };

  const handleChange = (fieldID, value) => {
    setFormData((prev) => ({ ...prev, [fieldID]: value }));
  };

  console.log(data);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-1000 flex justify-center items-center overflow-hidden">
      {loading ? (
        <LoadingSpinnerModal loading={loading} />
      ) : (
        <div className="w-[80vw] h-[90vh] p-8 bg-gray-200 rounded-lg flex flex-col gap-2 overflow-scroll relative">
          {currentItems.map((formDataItem) => (
            <form key={formDataItem.id} className="p-12 w-full h-full">
              <h1 className="text-center font-medium text-[25px]">
                {formDataItem.name}
              </h1>
              <p className="my-[50px]">
                <span className="font-medium mr-[5px]">Açıklama:</span>{" "}
                {formDataItem.description}
              </p>
              {formDataItem.formFields.map((field) => (
                <div className="mb-[20px]" key={field.formFieldID}>
                  <label
                    className="font-medium mr-[5px]"
                    htmlFor={`field-${field.formFieldID}`}
                  >
                    {field.name}:
                  </label>
                  {getFieldInputByType(
                    field,
                    handleChange,
                    setRating,
                    rating,
                    hover,
                    setHover
                  )}
                </div>
              ))}
            </form>
          ))}
          <div className="flex justify-center">
            <button
              onClick={onCancel}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              Vazgeç
            </button>
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-4 py-2 mx-1 bg-blue-500 text-white rounded ${
                currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              Geri
            </button>

            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={`px-4 py-2 mx-1 rounded cursor-pointer ${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-black"
                }`}
              >
                {index + 1}
              </button>
            ))}

            {currentPage === totalPages ? (
              <button
                onClick={handleSave}
                type="button"
                className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg "
              >
                Kaydet
              </button>
            ) : (
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`px-4 py-2 mx-1 bg-blue-500 text-white rounded ${
                  currentPage === totalPages
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
              >
                İleri
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// Helper function to get input by field type
const getFieldInputByType = (
  field,
  handleChange,
  setRating,
  rating,
  hover,
  setHover
) => {
  switch (field.type) {
    case 93:
      return (
        <input
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5"
          type="text"
          id={`field-${field.formFieldID}`}
          onChange={(e) => handleChange(field.formFieldID, e.target.value)}
        />
      );
    case 94:
      return (
        <textarea
          className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 h-40 p-2.5 hidden"
          id={`field-${field.formFieldID}`}
          onChange={(e) => handleChange(field.formFieldID, e.target.value)}
        ></textarea>
      );
    case 95:
      return <input type="radio" id={`field-${field.formFieldID}`} />;
    case 96:
      return <input type="checkbox" id={`field-${field.formFieldID}`} />;
    case 99:
      return (
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5"
          type="date"
          id={`field-${field.formFieldID}`}
          onChange={(e) => handleChange(field.formFieldID, e.target.value)}
        />
      );
    case 100:
      return <input type="file" id={`field-${field.formFieldID}`} />;
    case 97:
      const selectOptions = field.value
        .split(",")
        .map((word) => ({ value: word.trim(), label: word.trim() }));
      return (
        <Select
          options={selectOptions}
          className="w-48 my-1"
          placeholder={"Seçiniz"}
          maxMenuHeight={200}
          id={`field-${field.formFieldID}`}
          onChange={(option) => handleChange(field.formFieldID, option?.value)}
        />
      );
    case 98:
      return (
        <div className="flex gap-2" id={`field-${field.formFieldID}`}>
          {[...Array(5)].map((star, index) => {
            const currentRating = index + 1;

            return (
              <label key={currentRating}>
                <input
                  type="radio"
                  name={`rating-${field.formFieldID}`}
                  value={currentRating}
                  onClick={() => setRating(currentRating)}
                  className="hidden"
                />
                <FaStar
                  className="cursor-pointer"
                  size={30}
                  color={
                    currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"
                  }
                  onMouseEnter={() => setHover(currentRating)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </div>
      );
    default:
      return null;
  }
};

export default FormModal;
