import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    tr: {
      translation: {
        errors: {
          BadRequest: "Başarısız İstek",
          RecordAlredyExist: "Aynı kayıt zaten var",
          PasswordPoliticaError: "Şifre politikasına aykırı şifre girişi",
          NullParameter: "Dolu olması gereken bir alanı boş gönderdiniz",
          CompleError: "Komple Hata",
          UndefinedCurrency: "Tanımsız para birimi",
          AccountRecordNotFount: "Kayıt Bulunamadı",
          InvoiceItemsZeroLength: "Fatura Kalemi Bulunmuyor",
          InvoiceNumberError: "Fatura Numarası Hatası",
          TheValueInUse: "Değer kullanılıyor",
          MeetingInsertedButNotZoomMeetCreated: "Toplantı Eklendi fakat Zoom Toplantısı Oluşturulamadı",
          IdentityNumberNotValid: "Kimlik numarası geçerli değil",
          DefinedWageFount: "Bu ücret tipi tanımlı",
          PersonelIDMachineIDOrOperationIDMuesBeFill: "Personel ID, Makine ID veya Operasyon ID alanlarından biri dolu olmalıdır.",
          StatusError: "Statü Hatası",
          OrderStatusNotAvailable: "Sipariş Statüsü Uygun Değil",
          EntranceWareHouseNotFount: "Giriş Deposu Bulunamadı",
          ExitWareHouseNotFount: "Çıkış Deposu Bulunamadı",
          DeletingSlipsApproveOrSlipConfirmationisTrue: "Slip doğrulaması gerçekleşmedi",
          WorkOrderStatusNotProduction: "İş Emri Statüsü Üretimde Değil",
          FileExtensionIsNotTrue: "Dosya tipi doğru değil",
          TheMainProductMustBeFinishedOrSemiFinished: "Ana ürün mamul ya da yarı mamul olmalı",
          RawMaterialsAreNotAvailableInWarehouses: "Gerekli Hammadde Stoklarda Bulunmuyor",
          UnknownError: "Bilinmeyen bir hata oluştu"
        }
      }
    },
    en: {
      translation: {
        errors: {
            BadRequest: "Bad Request",
            RecordAlredyExist: "Record already exists",
            PasswordPoliticaError: "Password does not comply with the policy",
            NullParameter: "A required field was left empty",
            CompleError: "Complete Error",
            UndefinedCurrency: "Undefined currency",
            AccountRecordNotFount: "Record not found",
            InvoiceItemsZeroLength: "No invoice items found",
            InvoiceNumberError: "Invoice number error",
            TheValueInUse: "The value is in use",
            MeetingInsertedButNotZoomMeetCreated: "Meeting added but Zoom meeting could not be created",
            IdentityNumberNotValid: "ID number is not valid",
            DefinedWageFount: "This wage type is already defined",
            PersonelIDMachineIDOrOperationIDMuesBeFill: "One of the fields: Personnel ID, Machine ID, or Operation ID must be filled",
            StatusError: "Status error",
            OrderStatusNotAvailable: "Order status is not suitable",
            EntranceWareHouseNotFount: "Entrance warehouse not found",
            ExitWareHouseNotFount: "Exit warehouse not found",
            DeletingSlipsApproveOrSlipConfirmationisTrue: "Slip confirmation not completed",
            WorkOrderStatusNotProduction: "Work order status is not in production",
            FileExtensionIsNotTrue: "File type is incorrect",
            TheMainProductMustBeFinishedOrSemiFinished: "The main product must be finished or semi-finished",
            RawMaterialsAreNotAvailableInWarehouses: "Required raw materials are not available in stock"
            
        }
      }
    }
  },
  lng: "tr", // Varsayılan dil
  fallbackLng: "tr",
  interpolation: {
    escapeValue: false,
  }
});

export default i18n;
