import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import Breadcrumb from "../../components/bars/Breadcrumb";

const DepartmentDetail = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [token, setToken] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentUser, setDepartmentUser] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=16`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => {
              setUsers(res.data);
              const data = res.data;
              const newData = data.map((item) => ({
                value: item.userID,
                label: item.userName,
              }));
              setUserOptions(newData);
            })
            .catch((err) => console.error(err.data));
          api
            .get(
              `/api/Department/Get?id=${params.id}&customerID=${user.country}`
            )
            .then((res) => {
              setDepartmentData(res.data);
              setData(res.data);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  useEffect(() => {
    if (users && departmentData) {
      const matchedUser = users.find(
        (item) => item.userID === departmentData.userID
      );
      setDepartmentUser({
        value: matchedUser?.userID,
        label: matchedUser?.userName,
      });
    }
  }, [users, departmentData]);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Departmanlar", href: "/departments" },
                { label: data?.title || "Departman Detayı", href: "" },
              ]}
            />
            <div className="flex justify-center items-center h-screen flex-col mx-auto">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg min-w-[60rem] max-w-[60rem]">
                <div className="px-4 py-5 sm:px-6 bg-[#050A27]">
                  <h3 className="text-lg leading-6 font-medium text-white">
                    {data?.name}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-300">
                    {data?.title}
                  </p>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Atanan Kişi
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {departmentUser?.label}
                      </dd>
                    </div>

                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Açıklama
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data?.description}
                      </dd>
                    </div>
                  </dl>
                </div>
                <Link to={"/departments"}>
                  <button
                    type="button"
                    className="bg-[#050A27] hover:bg-[#050a27d7] focus:outline-none focus:ring-4 focus:ring-[#050A27] float-left text-white m-5 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    title="Geri dön"
                  >
                    <i className="fa-solid fa-arrow-left mr-2"></i>
                    Geri Dön
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default DepartmentDetail;
