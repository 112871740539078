import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import Select from "react-select";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { useTranslation } from "react-i18next";

const PersonelWageEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [wageStartDate, setWageStartDate] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [wageType, setWageType] = useState([]);
  const [data, setData] = useState([]);
  const [wageID, setWageID] = useState([]);
  const [personelID, setPersonelID] = useState([]);
  const [selectedWageType, setSelectedWageType] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=25`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get("/api/Common/GetParameters?parametername=wageType")
            .then((res) => {
              const wageData = res.data;
              const newWageData = wageData.map((item) => ({
                value: item.parameterID, //backend istenen
                label: item.parameterValue2, //ekrana basılan
              }));
              setWageType(newWageData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Personel/GetPersonWage?customerID=${user.country}&wageID=${params.id}`
            )
            .then((res) => {
              setData(res.data);
              setDescription(res.data.description);
              setAmount(res.data.amount);
              setWageID(res.data.wageID);
              setPersonelID(res.data.personelID);
              setWageStartDate(new Date(res.data.wageStartDate));
              setSelectedWageType({
                label: res.data.parameterValue2,
                value: res.data.parameterID,
              });
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  useEffect(() => {
    if (data && wageType) {
      const matchedWageType = wageType.find(
        (item) => item.value === data.wageType
      );

      if (matchedWageType) {
        setSelectedWageType({
          value: matchedWageType.value,
          label: matchedWageType.label,
        });
      }
    }
  }, [data, wageType]);

  const postData = {
    wageID,
    customerID,
    personelID,
    wageType: selectedWageType?.value,
    wageStartDate,
    amount,
    description,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.put("/api/Personel/PutPersonPage/PutPersonWage", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Maaş Eklendi !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/personels");
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="m-12 w-full min-h-screen">
            <div className="mb-5">
              <h1 className="text-3xl font-semibold title">Maaş Ekle</h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-2 gap-7 items-center w-full"
            >
              <div className="col-span-1">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Maaş :
                </label>
                <input
                  type="number"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="birthDate"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Başlangıç Tarihi :
                </label>
                <DatePicker
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  selected={wageStartDate}
                  onChange={(date) => setWageStartDate(date)}
                  locale={tr}
                  dateFormat={"dd/MM/yy"}
                />
              </div>
              <div className="">
                <label
                  htmlFor="wageType"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Maaş Tipi :
                </label>
                <Select
                  maxMenuHeight={200}
                  id="wageType"
                  options={wageType}
                  value={selectedWageType}
                  onChange={(e) => setSelectedWageType(e)}
                  placeholder="Seçiniz..."
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-span-2 flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg "
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PersonelWageEdit;
